import Vue from 'vue';
import { secureStorage } from '@/utils/secure-storage';
import firebase from 'firebase/app';
import _get from 'lodash/get';
import errorTypes from '@/utils/error/error-types';

function generateUUID() {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export default {
  namespaced: true,
  state: {
    authenticatedThruSignin: false,
    authenticated: false,
    currentUser: null,
    currentUserAlias: null,
    startupPath: null,
    lastFailedSignInEmail: '',
    deviceId: secureStorage.getItem('hello::deviceId'),
    emailVerificationContext: null,
  },
  getters: {
    authenticated: (state) => state.authenticated,
    currentUser: (state) => state.currentUser,
    currentUserAlias: (state) => state.currentUserAlias,
    startupPath: (state) => state.startupPath,
    authenticatedThruSignin: (state) => state.authenticatedThruSignin,
    deviceId: (state) => state.deviceId,
    lastFailedSignInEmail: (state) => state.lastFailedSignInEmail,
  },
  mutations: {
    emailVerificationContext(state, context) {
      state.emailVerificationContext = context;
    },
    lastFailedSignInEmail(state, email) {
      state.lastFailedSignInEmail = email;
    },
    authenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    authenticatedThruSignin(state, authenticatedThruSignin) {
      state.authenticatedThruSignin = authenticatedThruSignin;
    },
    currentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    currentUserAlias(state, userAlias) {
      state.currentUserAlias = userAlias;
    },
    deviceId(state, deviceId) {
      state.deviceId = deviceId;
    },
    startupPath(state, startupPath) {
      state.startupPath = startupPath;
    },
  },
  actions: {
    async init({ commit, state, dispatch }) {
      try {
        if (!state.deviceId) {
          const deviceId = generateUUID();
          secureStorage.setItem('hello::deviceId', deviceId);
          commit('deviceId', deviceId);
        }

        const parameters = new URLSearchParams(window.location.search);
        const session = parameters.get('session');
        if (session) {
          console.log('SESSION Detected', session); // eslint-disable-line no-console
          await dispatch('setCustomToken', decodeURI(session));
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        await dispatch('setAuth', null);
      }
    },

    setEmailVerificationContext({ commit }, verification_context) {
      commit('emailVerificationContext', verification_context);
    },

    resetLastFailedSignInEmail({ commit }) {
      commit('lastFailedSignInEmail', null);
    },

    resetEmailVerificationContext({ commit }) {
      commit('emailVerificationContext', null);
    },

    async setStartupPath({ commit }, startupPath) {
      commit('startupPath', startupPath);
    },

    async setCustomToken({ commit, dispatch }, customToken) {
      commit('authenticatedThruSignin', false);
      try {
        await Vue.prototype.$firebaseAuth.signOut();
      } finally {
        await dispatch('setAuth', null);
      }
      return Vue.prototype.$firebaseAuth.signInWithCustomToken(customToken);
    },

    async setAuth({ state, commit, dispatch }, user) {
      if (user) {
        commit('authenticated', true);
        const token = await user.getIdToken();
        commit('currentUser', token);
        commit('currentUserAlias', user.email);

        rg4js('setUser', {
          identifier: user.email,
          isAnonymous: false,
          email: user.email,
        });
        dispatch('account/getAccount', null, { root: true });
      } else {
        commit('authenticated', false);
        commit('currentUser', null);
        commit('currentUserAlias', null);
        dispatch('account/resetAccountStore', null, { root: true });

        rg4js('setUser', {
          identifier: state.deviceId,
          isAnonymous: true,
        });
        if (window.hj) {
          hj('identify', null);
        }
      }
    },

    async userLogin({ state, dispatch, commit }, { email, password }) {
      await Vue.prototype.$firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      return Vue.prototype.$firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(async (signin) => {
          commit('lastFailedSignInEmail', null);
          commit('authenticatedThruSignin', true);
        })
        .catch(async (err) => {
          await dispatch('setAuth', null);
          await dispatch('account/resetAccountStore', null, { root: true });
          commit('authenticatedThruSignin', false);
          console.error(JSON.stringify(err)); // eslint-disable-line no-console
          if (err.code) {
            if (err.code === 'auth/user-not-found') {
              commit('lastFailedSignInEmail', email);
            }
            throw new errorTypes.HttpError(400, { error: { code: 'auth.' + err.code.replace(/\//g, '.'), title: err.message } });
          }
          throw err;
        });
    },

    async userRegister({ state, dispatch, commit }, { email, password, language, recaptcha_token }) {
      await Vue.prototype.$services.account.registerAccount({ email, password, language, recaptcha_token, verification_context: state.emailVerificationContext });

      return Vue.prototype.$firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(async () => {
          commit('authenticatedThruSignin', true);
        })
        .catch(async (err) => {
          await dispatch('setAuth', null);
          commit('authenticatedThruSignin', false);
          throw err;
        });
    },

    async userSignOut({ dispatch, commit }) {
      return Vue.prototype.$firebaseAuth
        .signOut()
        .then(async () => {
          await dispatch('account/resetAccountStore', null, { root: true });
        })
        .catch(async (err) => {
          await dispatch('setAuth', null);
          commit('authenticatedThruSignin', false);
          commit('startupPath', null);
          await dispatch('account/resetAccountStore', null, { root: true });
          throw err;
        });
    },

    async forgotPassword({}, { recaptcha_token, email }) {
      return Vue.prototype.$services.security.forgotPassword(recaptcha_token, { email });
    },

    async changePassword({}, { recaptcha_token, token, password }) {
      return await Vue.prototype.$services.security.confirmResetPassword(recaptcha_token, { token, password });
    },

    async updateEmail({}, { email, language }) {
      return await Vue.prototype.$services.security.updateEmail(email, language);
    },

    async resetPassword() {
      return await Vue.prototype.$services.security.resetPassword();
    },

    async resetEmailVerification({ rootState, state }) {
      return await Vue.prototype.$services.security.resendEmailVerification(_get(rootState, 'ui.currentLocale', 'en'), state.emailVerificationContext);
    },
    async verifyEmailToken({ dispatch, state }, token) {
      await Vue.prototype.$services.security.verifyEmailToken(token);

      if (state.authenticated) {
        await dispatch('account/checkEmailVerified', null, { root: true });
      }
    },
  },
};
