import moment from 'moment-timezone';

export default ({ Vue, store }) => {
  Vue.filter('formatShortDateWithHour', (date) => {
    if (date) {
      const locale = store.getters['ui/currentLocale'];
      switch (locale) {
        case 'en':
          return moment(date).format(`YYYY/MM/DD${extractLocalizedHourFormat(locale)}`);
        case 'fr':
          return moment(date).format(`DD/MM/YYYY${extractLocalizedHourFormat(locale)}`);
        default:
          return moment(date).format(`DD/MM/YYYY${extractLocalizedHourFormat(locale)}`);
      }
    }

    function extractLocalizedHourFormat(locale) {
      const timezonedMomentDate = moment(date);

      const isTimezonedMidnight = timezonedMomentDate.hour() === 0 && timezonedMomentDate.minute() === 0;
      if (isTimezonedMidnight) {
        return '';
      }

      switch (locale) {
        case 'en':
          return ' [at] h[:]mm a';
        case 'fr':
          return ' [à] H[h]mm';
        default:
          return ' [à] H[h]mm';
      }
    }
  });

  Vue.filter('formatHourWithAt', (date) => {
    const timezonedMomentDate = moment(date);

    const isTimezonedMidnight = timezonedMomentDate.hour() === 0 && timezonedMomentDate.minute() === 0;
    if (isTimezonedMidnight) {
      return '';
    }

    if (date) {
      switch (store.getters['ui/currentLocale']) {
        case 'en':
          return moment(date).format('[at] h[:]mm a');
        case 'fr':
          return moment(date).format('[à] H[h]mm');
        default:
          return moment(date).format('[à] h[:]mm a');
      }
    }
  });

  Vue.filter('formatShortDate', (date) => {
    if (date) {
      switch (store.getters['ui/currentLocale']) {
        case 'en':
          return moment(date).format('YYYY/MM/DD');
        case 'fr':
          return moment(date).format('DD/MM/YYYY');
        default:
          return moment(date).format('DD/MM/YYYY');
      }
    }
  });

  Vue.filter('formatDateText', (date) => {
    if (date) {
      switch (store.getters['ui/currentLocale']) {
        case 'en':
          return moment(date).locale('en').format('MMMM Do');
        case 'fr':
          return moment(date).locale('fr').format('Do MMMM');
        default:
          return moment(date).locale('fr').format('Do MMMM');
      }
    }
  });

  Vue.filter('formatShortDateWithoutDays', (date) => {
    if (date) {
      switch (store.getters['ui/currentLocale']) {
        case 'en':
          return moment(date).format('YYYY/MM');
        case 'fr':
          return moment(date).format('MM/YYYY');
        default:
          return moment(date).format('MM/YYYY');
      }
    }
  });

  Vue.filter('formatTextDate', (date) => {
    if (date) {
      switch (store.getters['ui/currentLocale']) {
        case 'en':
          return moment(date).locale('en').format('MMMM DD, YYYY');
        case 'fr':
          return moment(date).locale('fr').format('D MMMM YYYY');
        default:
          return moment(date).locale('fr').format('D MMMM YYYY');
      }
    }
  });
};
