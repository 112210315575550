<template>
  <h-form class="corpo-order-step frame" ref="form" v-model="valid" @submit.prevent="submit()">
    <div class="frame-content">
      <h3>{{ $t('components.corpo_order_step.title') }}</h3>

      <div class="sections">
        <div class="order-section">
          <h-text-field v-model="quantity" :label="$t('components.corpo_order_step.quantity_label')" :hint="$t('components.corpo_order_step.quantity_hint')" persistent-hint type="number" class="input" :rules="quantityRules" v-maska="'###'" />
          <h-text-field
            v-model="amount"
            suffix="$"
            :label="$t('components.corpo_order_step.amount_label')"
            :hint="$t('components.corpo_order_step.amount_hint', { min_amount: minimumPurchaseAmount })"
            persistent-hint
            type="number"
            class="input"
            :rules="amountRules"
            v-maska="'####'"
          />
          <div class="subtotal">
            <div class="subtotal-label">{{ $t('components.corpo_order_step.subtotal_label') }}</div>
            <div :class="['subtotal-value', { 'error-text': exceedMaxValue }]">{{ subtotalValue | currency_full | currencySymbol }}</div>
          </div>
        </div>

        <div class="msg-section">
          <h-textarea
            v-model="message"
            :label="$t('components.corpo_order_step.msg_label')"
            :hint="$t('components.corpo_order_step.msg_hint')"
            :placeholder="$t('components.corpo_order_step.msg_placeholder')"
            persistent-hint
            persistent-placeholder
            height="140"
            maxlength="150"
            :rules="messageRules"
            counter
          />
          <div class="btn-container">
            <h-btn type="submit" class="btn" :disabled="!valid || working" :loading="working" block>{{ $t('components.corpo_order_step.proceed_text') }}</h-btn>
          </div>
        </div>
      </div>

      <corpo-order-error-modal v-model="errorModal" />
    </div>
  </h-form>
</template>

<script>
import _get from 'lodash/get';
import CorpoOrderErrorModal from '@/components/corpo/order-process/CorpoOrderErrorModal';
import safeExecute from '@/composables/safe-execute';

import { validateMaxLength, validateMaxNumLines, validateMaxValue, validateMinValue, validatePositiveInteger, validateRequiredGivenFieldLabel } from '@/composables/validations';
import { mapGetters } from 'vuex';

const MAX_VALUE = 10000;

export default {
  name: 'corpo-order-step-v2',
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      validateRequiredGivenFieldLabel,
      validateMaxNumLines,
      validateMaxLength,
      validateMinValue,
      validateMaxValue,
      validatePositiveInteger,
      executeForm,
      execute,
      working,
    };
  },
  components: { CorpoOrderErrorModal },
  data() {
    return {
      valid: false,
      quantity: null,
      amount: null,
      message: '',
      errorModal: false,
      quantityRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_order_step.quantity_label')), this.validateMinValue(1), this.validateMaxValue(200), this.validatePositiveInteger()],
      messageRules: [this.validateMaxLength(150), this.validateMaxNumLines(6)],
    };
  },
  computed: {
    ...mapGetters('offer', ['offerCorporateDetails']),
    subtotalValue() {
      return this.quantity * this.amount;
    },
    amountRules() {
      return [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_order_step.amount_label')), this.validateMinValue(this.minimumPurchaseAmount), this.validateMaxValue(500), this.validatePositiveInteger()];
    },
    minimumPurchaseAmount() {
      return _get(this.offerCorporateDetails, 'minimum_purchase_amount', 1);
    },
    orderPayload() {
      return {
        quantity: this.quantity,
        amount: this.amount,
        message: this.message,
      };
    },
    exceedMaxValue() {
      return this.subtotalValue > MAX_VALUE;
    },
    // TODO (PLP) :  ON BLUR?  WHY NOT ON SUBMIT? NEED TO VALIDATE
  },
  methods: {
    submit() {
      if (this.exceedMaxValue) {
        this.errorModal = true;
      } else if (this.$refs.form.validate()) {
        this.saveOrderAndProceed();
      }
    },
    saveOrderAndProceed() {
      this.execute(
        {
          action: 'corpo/setOrder',
          success: () => {
            this.$emit('next');
          },
        },
        this.orderPayload,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sections {
  @media ($md-min) {
    display: flex;
    align-items: flex-start;
  }
}

.order-section {
  @media ($sm-min) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.input {
  width: 100%;
}

.subtotal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--gap-small) 0 var(--gap);

  @media ($md-min) {
    margin: 40px 0 0;
  }
}

.subtotal-label {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 600;
  color: var(--color-text-secondary);
}
.subtotal-value {
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 600;

  &.error-text {
    color: var(--color-error);
  }
}

.input,
.subtotal-label,
.subtotal-value {
  @media ($sm-min) {
    width: calc((100% - var(--gap)) / 2);
    flex: 0 0 auto;
    text-align: right;
  }
}

::v-deep input[type='number'] {
  text-align: right;
}

// Remove spin buttons on number inputs (up/down buttons)
::v-deep input[type='number']::-webkit-outer-spin-button,
::v-deep input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

.msg-section {
  text-align: center;
  flex: 0 0 auto;
  @media ($md-min) {
    width: 354px;
    margin-left: var(--gap-xlarge);
  }
}

.btn {
  margin-top: var(--gap-large);
}

::v-deep .v-textarea .v-input__slot {
  align-items: stretch;
  height: 140px;
}
</style>
