<template>
  <small-card-base :image="offer.offer_branding.images.showcases[currentLocale][0]" :show-panier-bleu="offer.offer_branding.features.panier_bleu" :offer-title="offer.offer_branding.label[currentLocale]">
    <template #content>
      <small-card-base-badge use-gift-icon :text="offer.incentive.bonus_display" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

      <small-card-base-description :text="offer.offer_branding.description[currentLocale]" />

      <small-card-voucher-status-label :cta-purchase="offer.cta.purchase" :status="offer.status" :start="offer.availability.start_date" />
    </template>

    <template #call-to-action>
      <small-card-base-cta-link v-for="(action, index) in allowedActions.slice(0, -1)" @click="executeActionByCode(action.callback_type)" :text="$t(action.label)" :key="index" />
      <h-btn v-if="allowedActions.length > 0" class="mt-5" color="var(--color-branding)" block large @click="executeActionByCode(_last(allowedActions).callback_type)">{{ $t(_last(allowedActions).label) }}</h-btn>
    </template>
  </small-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseDescription from '@/components/cards/small/SmallCardBaseDescription.vue';
import SmallCardVoucherStatusLabel from '@/components/cards/small/SmallCardVoucherStatusLabel.vue';
import SmallCardBaseCtaLink from '@/components/cards/small/SmallCardBaseCtaLink.vue';
import _last from 'lodash/last';
import { CallbackType, extractOfferDisplayInfos } from '@/composables/offer-action-display-selector';

export default {
  components: { SmallCardBase, SmallCardBaseDescription, SmallCardBaseBadge, SmallCardVoucherStatusLabel, SmallCardBaseCtaLink },
  name: 'gift-certificate-offer-single-publisher-small-card',
  props: {
    image: {
      type: String,
    },
    offer: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId']),
    allowedActions() {
      return extractOfferDisplayInfos(this.offer);
    },
  },
  methods: {
    _last,
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    executeActionByCode(callback_type) {
      switch (callback_type) {
        case CallbackType.SEE_STORES:
          this.seeStoresAction();
          break;
        case CallbackType.SEE_OFFER:
          this.seeStoresAction();
          break;
        case CallbackType.ACTIVATE_VOUCHER:
          this.redeemNowAction();
          break;
        case CallbackType.BUY_AS_GIFT:
          this.buyAsGiftAction();
          break;
        default:
          break;
      }
    },
    async seeStoresAction() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer.id, _position: { x: 0, y: window.scrollY } } });
    },
    async redeemNowAction() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferActivation', params: { space: this.currentSpaceSlug, offer_id: this.offer.id } });
    },
    async buyAsGiftAction() {
      return this.openHeaderDrawerWithParams({
        content: 'purchaseGiftCardOverlay',
        payload: {
          offer_id: this.offer.id,
          partner_id: this.currentPartnerId,
        },
      });
    },
  },
};
</script>
