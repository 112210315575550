import { computed, reactive, watch } from '@vue/composition-api';

export default function errorHandling(store) {
  const currentError = reactive({});

  const computedError = computed(() => store.getters['error/currentError']);

  watch(computedError, () => {
    currentError.value = computedError.value;
  });

  const getErrorTranslation = (translateFunction) => {
    if (currentError.value) {
      if (currentError.value.code === 'errors.app') {
        return currentError.value.msg;
      }

      let params = { message: currentError.value.msg };
      if (currentError.value.data) {
        params = { ...params, ...currentError.value.data };
      }
      return translateFunction.$t(currentError.value.code, params);
    }
  };

  const getGenericErrorMessage = (translateFunction, code) => {
    if (currentError.value) {
      return translateFunction.$t('errors.refresh_required', { code: code });
    }
  };

  return { currentError, getErrorTranslation, getGenericErrorMessage };
}
