<template>
  <div aria-live="polite">
    <v-expand-transition>
      <h-error-box v-bind="$attrs" v-if="isFormError" :text="errorTranslation()" />
    </v-expand-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import errorHandling from '@/composables/error-handling';

export default {
  setup(props, { root }) {
    const { getErrorTranslation } = errorHandling(root.$store);
    return {
      getErrorTranslation,
    };
  },
  name: 'hello-form-error',
  inheritAttrs: false,
  computed: {
    ...mapGetters('error', ['isFormError']),
  },
  methods: {
    ...mapActions('error', ['clearError']),
    errorTranslation() {
      return this.getErrorTranslation(this);
    },
  },
};
</script>
