<template>
  <div class="flow-info">
    <div class="info-header">
      <div class="outer-icon">
        <v-icon class="inner-icon" color="ultra-light">mdi-account</v-icon>
      </div>
      <h4 class="header-title">{{ $t('components.redeem_flow_info.title') }}</h4>
      <div class="header-subtext">{{ $t('components.redeem_flow_info.subtitle') }}</div>
    </div>

    <ol class="flow-steps">
      <li v-for="(item, index) in infoSteps" :key="index" class="list-item">
        <div class="item-icon-container">
          <img class="item-icon" :src="require('@/assets/' + item.src)" :alt="item.alt" />
        </div>
        <div class="text-container">
          <div class="circle">
            {{ item.step }}
          </div>
          <div class="h-text-3" style="grid-column-start: 3; grid-row: 1 / span 2">
            {{ item.info_text }}
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'redeem-flow-info',
  computed: {
    infoSteps() {
      return [
        {
          step: 1,
          src: 'login_form.svg',
          alt: this.$t('components.redeem_flow_info.step_1.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_1.info_text'),
        },
        {
          step: 2,
          src: 'redeem_details.svg',
          alt: this.$t('components.redeem_flow_info.step_2.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_2.info_text'),
        },
        {
          step: 3,
          src: 'card_envelop.svg',
          alt: this.$t('components.redeem_flow_info.step_3.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_3.info_text'),
        },
        {
          step: 4,
          src: 'hello_card_pos.svg',
          alt: this.$t('components.redeem_flow_info.step_4.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_4.info_text'),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.flow-info {
  margin: 0 auto var(--gap-xlarge);
  padding: 24px var(--gap);
  border: var(--border-thin) solid var(--color-border);
  text-align: center;
}

.inner-icon {
  background-color: var(--color-primary);
  font-size: 45px;
  clip-path: circle(50%);
  height: 35px;
  width: 35px;
  padding-top: 5px;
}

.outer-icon {
  margin: 0 auto var(--gap-xsmall) auto;
  border: var(--border-thick) solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 50%;
  width: fit-content;
}

.info-header {
  margin-bottom: calc(var(--gap) * 3);
  @media ($sm-min) {
    margin-bottom: var(--gap-xxlarge);
  }

  line-height: 1.5;
  & > *:not(:last-child) {
    margin-bottom: var(--gap-xsmall);
  }
}

.header-subtext {
  color: var(--color-text-secondary);
}

.flow-steps {
  text-align: left;
  list-style-type: none;

  max-width: 218px;
  margin: 0 auto;
  padding-left: 0;

  --circle-radius: 10px;

  --circle-diameter: calc(2 * var(--circle-radius));

  @media ($sm-min) {
    --circle-radius: 12px;
  }

  .text-container {
    display: grid;
    grid-template-columns: var(--circle-diameter) 12px 1fr;
    grid-template-rows: var(--circle-diameter) 1fr;
  }

  .circle {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background: var(--color-text-primary);
    display: inline-block;
    line-height: 1.4;
    font-size: var(--circle-radius);
    margin-right: var(--gap-small);
    margin-left: var(--circle-offset);
    align-content: center;
  }

  .item-icon-container {
    text-align: center;
    align-content: center;
    height: 114px;
    margin-bottom: var(--gap-xsmall);
  }

  @media ($md-min) {
    max-width: none;
    display: flex;
    flex-basis: auto;
    justify-content: space-around;
    & > * {
      flex-basis: 200px;
    }
  }

  li:not(:last-child) {
    margin-bottom: calc(var(--gap) * 3);
    @media ($md-min) {
      margin-bottom: 0;
    }
  }
}
</style>
