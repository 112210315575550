import _concat from 'lodash/concat';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _remove from 'lodash/remove';
import _uniq from 'lodash/uniq';
import Vue from 'vue';

import { secureStorage } from '@/utils/secure-storage';

const EMPTY_SPACE = {
  name: null,
  slug: null,
  partner_id: null,
  label: {},
  description: {},
  tagline: {},
  url: {},
  image_showcase: [],
  image_background: {},
  image_big_hero: {},
  small_hero: {},
  horizontal_logo: {},
  phone: undefined,

  subscription_plan: undefined,
};

export default {
  namespaced: true,
  state: {
    currentSpaceSlug: secureStorage.getItem('hello::currentSpaceSlug'),
    currentBrandingColor: '#000000',
    currentBrandingName: {},
    currentBrandingBackgroundImages: {},
    currentBrandingHeroBig: {},
    currentBrandingHeroSmall: {},
    currentBrandingHorizontalLogo: {},
    currentBrandingDescription: {},
    currentBrandingTagLine: {},
    currentBrandingUrl: {},
    currentBrandingPhone: undefined,
    currentPartnerId: undefined,
    currentSpace: EMPTY_SPACE,
    publicSpaces: [],
    publicSpacesPartnerIdMap: {},
    spaceHistory: secureStorage.getItem('hello::spaceHistory') ? secureStorage.getItem('hello::spaceHistory').split(',') : [],
  },
  getters: {
    publicSpaceByPartnerIdColorGetter: (state, getters, rootState, rootGetters) => (partnerId) => _get(state.publicSpacesPartnerIdMap, `${partnerId}.brand_color`),
    publicSpaceByPartnerIdLogoGetter: (state, getters, rootState, rootGetters) => (partnerId) => _get(state.publicSpacesPartnerIdMap, `${partnerId}.logo_image_url.${rootGetters['ui/currentLocale']}`),
    publicSpaceByPartnerIdBrandingNameGetter: (state, getters, rootState, rootGetters) => (partnerId) => _get(state.publicSpacesPartnerIdMap, `${partnerId}.name.${rootGetters['ui/currentLocale']}`),
    publicSpaceByPartnerIdSlugGetter: (state) => (partnerId) => _get(state.publicSpacesPartnerIdMap, `${partnerId}.slug`),
    brandingColor: (state) => state.currentBrandingColor,
    backgroundImageUrl: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingBackgroundImages[rootGetters['ui/currentLocale']];
    },
    currentBrandingHeroBig: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingHeroBig[rootGetters['ui/currentLocale']];
    },
    currentBrandingHeroSmall: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingHeroSmall[rootGetters['ui/currentLocale']];
    },
    currentBrandingHorizontalLogo: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingHorizontalLogo[rootGetters['ui/currentLocale']];
    },
    currentSpaceSubscriptionPlan: (state) => {
      return state.currentSpace.subscription_plan;
    },
    currentSpaceSlug: (state) => state.currentSpaceSlug,
    hasCurrentSpace: (state) => state.currentSpace.name !== null,
    currentPartnerId: (state) => state.currentPartnerId,
    currentBrandingName: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingName[rootGetters['ui/currentLocale']];
    },
    currentBrandingDescription: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingDescription[rootGetters['ui/currentLocale']];
    },
    currentBrandingPhone: (state) => state.currentBrandingPhone,
    currentBrandingUrl: (state, getters, rootState, rootGetters) => {
      return state.currentBrandingUrl[rootGetters['ui/currentLocale']];
    },
    publicSpaces: (state) => state.publicSpaces,
    spaceHistory: (state) =>
      _reduce(
        state.spaceHistory,
        (acc, slug) => {
          const spaceItem = secureStorage.getItem(`hello::space::${slug}`);
          if (spaceItem) {
            const space = JSON.parse(spaceItem);
            acc.push({ slug, ...space });
          } else {
            acc.push({
              slug,
              branding: {
                branding_color: '',
                description: { en: '', fr: '' },
                images: { showcases: { fr: [], en: [] }, background_image: { en: null, fr: null }, big_hero: { en: null, fr: null }, horizontal_logo: { en: null, fr: null } },
                label: { fr: '', en: '' },
                phone: '',
                tagline: { fr: '', en: '' },
                url: { fr: '', en: '' },
              },
              default_language: 'fr',
              name: '',
              partner_id: '',
              private_space: undefined,
            });
          }
          return acc;
        },
        [],
      ),
  },
  mutations: {
    currentSpaceSlug(state, currentSpaceSlug) {
      state.currentSpaceSlug = currentSpaceSlug;
    },
    spaceHistory(state, history) {
      state.spaceHistory = history;
    },
    currentSpace(state, currentSpace) {
      if (!currentSpace) {
        currentSpace = EMPTY_SPACE;
      }
      state.currentSpace = currentSpace;
      state.currentPartnerId = _get(currentSpace, 'partner_id', '');
      state.currentBrandingName = _get(currentSpace, 'branding.label', '');
      state.currentBrandingDescription = _get(currentSpace, 'branding.description', '');
      state.currentBrandingColor = _get(currentSpace, 'branding.branding_color', '#000000') || '#000000';
      state.currentBrandingBackgroundImages = _get(currentSpace, 'branding.images.background_image', {});
      state.currentBrandingHeroBig = _get(currentSpace, 'branding.images.big_hero', {});
      state.currentBrandingHeroSmall = _get(currentSpace, 'branding.images.small_hero', {});
      state.currentBrandingHorizontalLogo = _get(currentSpace, 'branding.images.horizontal_logo', {});
      state.currentBrandingPhone = _get(currentSpace, 'branding.phone', undefined);
      state.currentBrandingUrl = _get(currentSpace, 'branding.url', {});
    },
    publicSpaces(state, publicSpaces) {
      state.publicSpaces = _map(publicSpaces, (publicSpace) => {
        return {
          slug: publicSpace.slug,
          name: publicSpace.branding.label,
          partner_id: publicSpace.partner_id,
          banner_image_url: _get(publicSpace, 'branding.images.horizontal_logo', {}),
          brand_color: _get(publicSpace, 'branding.branding_color', '#000000') || '#000000',
        };
      });
      state.publicSpacesPartnerIdMap = _reduce(
        publicSpaces,
        (acc, publicSpace) => {
          acc[publicSpace.partner_id] = {
            slug: publicSpace.slug,
            name: publicSpace.branding.label,
            partner_id: publicSpace.partner_id,
            logo_image_url: _get(publicSpace, 'branding.images.horizontal_logo', {}),
            brand_color: _get(publicSpace, 'branding.branding_color', '#000000') || '#000000',
          };
          return acc;
        },
        {},
      );
    },
  },
  actions: {
    async init({ state, dispatch }) {
      try {
        if (state.currentSpaceSlug) {
          await dispatch('setCurrentSpaceSlug', state.currentSpaceSlug);
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    },

    async setCurrentSpaceSlug({ dispatch, commit, state, rootGetters }, currentSpaceSlug) {
      if (currentSpaceSlug) {
        const space = await Vue.prototype.$services.space.getSpaceBySlug(currentSpaceSlug);
        if (!space) {
          commit('currentSpaceSlug', null);
          commit('currentSpace', null);
          secureStorage.removeItem('hello::currentSpaceSlug');
          let newSpaceHistory = _uniq(_concat([], currentSpaceSlug, state.spaceHistory));
          _remove(newSpaceHistory, (e) => e === currentSpaceSlug);
          secureStorage.setItem('hello::spaceHistory', newSpaceHistory);
          commit('spaceHistory', newSpaceHistory);
          return false;
        }
        if (window.fbq) {
          fbq('trackCustom', 'SpaceView', {
            partner_id: space.partner_id,
          });
        }
        commit('currentSpaceSlug', currentSpaceSlug);
        commit('currentSpace', space);
        secureStorage.setItem('hello::currentSpaceSlug', currentSpaceSlug);
        commit('spaceHistory', _uniq(_concat([], currentSpaceSlug, state.spaceHistory)));
        secureStorage.setItem('hello::spaceHistory', _uniq(_concat([], currentSpaceSlug, state.spaceHistory)));
        secureStorage.setItem(`hello::space::${currentSpaceSlug}`, JSON.stringify(state.currentSpace));
        dispatch('subscription/setSubscriptionRequired', space.subscription_required, { root: true });
        dispatch('subscription/setCurrentSubscriptionPlan', space.subscription_plan, { root: true });

        if (space.partner_region && !rootGetters['offer/currentRegion']) {
          dispatch('offer/updateRegion', space.partner_region, { root: true });
        }
      } else {
        commit('currentSpaceSlug', null);
        commit('currentSpace', null);
        secureStorage.removeItem('hello::currentSpaceSlug');
      }
      return true;
    },

    async getSpaceByPartnerId({}, partner_id) {
      const spaces = await Vue.prototype.$services.space.getSpaceByPartnerId(partner_id);
      return spaces;
    },
    async listPublicSpaces({ commit }) {
      const spaces = await Vue.prototype.$services.space.listPublicSpaces();

      const adjustedSpaces = [];
      if (spaces && spaces.items) {
        _map(spaces.items, (space) => {
          if (!space.content) {
            space.content = {};
          }
          space.content.banner_image_url = _get(space, 'content.banner_image_url', '/img/default_space_banner.png');
          adjustedSpaces.push(space);
        });
      }

      commit('publicSpaces', adjustedSpaces);
    },
  },
};
