<template>
  <small-card-base :restricted-to-members="offer.features.restricted_to_members" :image="offer.offer_branding.images.showcases[currentLocale][0]" :offer-title="offer.offer_branding.label[currentLocale]">
    <template #content>
      <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

      <h-progress-bar
        class="progress-bar"
        :font-size="13"
        :status="offer.status"
        :start="offer.availability.start_date"
        :expiration="offer.availability.end_date"
        :current-number="offer.availability.inventory.adjusted_avail_payment_amount"
        :total-number="offer.availability.inventory.adjusted_total_payment_amount"
        :min-purchase="offer.incentive.minimum_payment"
        :max-purchase="offer.incentive.adjusted_user_payment_limit"
      />
    </template>

    <template #call-to-action>
      <small-card-base-cta-link v-for="(action, index) in allowedActions.slice(0, -1)" :text="$t(action.label)" :key="index" @click="executeActionByCode(action.callback_type)" />
      <h-btn v-if="allowedActions.length > 0" class="mt-5" color="var(--color-branding)" block large @click="executeActionByCode(_last(allowedActions).callback_type)">{{ $t(_last(allowedActions).label) }}</h-btn>
    </template>
  </small-card-base>
</template>

<script>
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import { remainingAmount, totalAmount } from '@/composables/offer-publisher-helper';
import { CallbackType, extractOfferDisplayInfos } from '@/composables/offer-action-display-selector';
import SmallCardBaseCtaLink from '@/components/cards/small/SmallCardBaseCtaLink.vue';
import _last from 'lodash/last';

export default {
  setup() {
    return {
      remainingAmount,
      totalAmount,
    };
  },
  components: { SmallCardBaseCtaLink, SmallCardBase, SmallCardBaseBadge },
  name: 'bonus-offer-multiple-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug']),
    allowedActions() {
      return extractOfferDisplayInfos(this.offer);
    },
  },
  methods: {
    _last,
    executeActionByCode(callback_type) {
      switch (callback_type) {
        case CallbackType.SEE_OFFER:
          this.seeOfferAction();
          break;
        default:
          break;
      }
    },
    seeOfferAction() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer.id, _position: { x: 0, y: window.scrollY } } });
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  margin-bottom: 52px;
}
</style>
