export default {
  name: 'paysafe',
  init: async ({ Vue }) => {
    Vue.prototype.$paysafe = {};
    Vue.prototype.$paysafe.setup = async (extraOption) => {
      return window.paysafe.fields.setup(process.env.VUE_APP_PAYSAFE_KEY, {
        currencyCode: 'CAD',
        supportedCountries: ['CA'],
        environment: process.env.VUE_APP_PAYSAFE_ENV,
        accounts: {
          default: Number(process.env.VUE_APP_PAYSAFE_ACCOUNT_ID),
        },
        ...extraOption,
      });
    };
  },
};
