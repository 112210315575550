<template>
  <h-dialog @close="closeActivateCardModal" :title="$t('components.account_info_bar_activate_card.modal_title')">
    <template v-slot:activator="{ on, attrs }">
      <h-btn v-on="on" v-bind="attrs" inverted color="primary" style="align-self: center">{{ $t('components.account_info_bar_activate_card.action_title') }}</h-btn>
    </template>

    <p>{{ $t('components.account_info_bar_activate_card.modal_text') }}</p>

    <h-form ref="activateForm" v-model="valid">
      <hello-form-error />

      <h-text-field
        v-show="!activationSuccess"
        :rules="cardActivationCodeRules"
        type="tel"
        maxlength="6"
        class="my-4"
        v-maska="'######'"
        :label="$t('components.account_info_bar_activate_card.modal_input_label')"
        :hint="$t('components.account_info_bar_activate_card.modal_input_hint')"
        v-model="activationCode"
      />

      <h-action-layout v-show="!activationSuccess">
        <h-btn @click="activateCard" :loading="working" :disabled="working || !valid">{{ $t('components.account_info_bar_activate_card.modal_action') }}</h-btn>
      </h-action-layout>

      <v-expand-transition>
        <h-success-box style="margin: 24px 0" v-show="activationSuccess" :text="$t('components.account_info_bar_activate_card.modal_success_text')" />
      </v-expand-transition>

      <div v-show="activationSuccess && cardPin" class="nip-container">
        <p class="nip-text">{{ $t('components.account_info_bar_activate_card.modal_need_pin') }}</p>
        <p class="nip-text">{{ nipValue }}</p>
        <h-btn @click="revealNip" :disabled="showNip">{{ $t('components.account_info_bar_activate_card.modal_action_show_pin') }}</h-btn>
      </div>
    </h-form>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { validateInvalidActivationCode, validateRequired } from '@/composables/validations';
import { mapActions } from 'vuex';

export default {
  name: 'activate-card-modal',
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      execute,
      executeForm,
      working,
      validateRequired,
      validateInvalidActivationCode,
    };
  },
  data() {
    return {
      valid: false,
      activationSuccess: false,
      showNip: false,
      cardPin: '',
      activationCode: null,
      cardActivationCodeRules: [this.validateRequired('activation_code'), this.validateInvalidActivationCode()],
    };
  },
  props: {
    publicToken: {
      type: String,
      required: true,
    },
  },
  computed: {
    nipValue() {
      if (this.showNip) {
        return this.cardPin[0] + ' ' + this.cardPin[1] + ' ' + this.cardPin[2] + ' ' + this.cardPin[3];
      }
      return '* * * *';
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    revealNip() {
      if (this.activationSuccess) {
        this.showNip = true;
      }
    },
    activateCard() {
      const _this = this;
      this.executeForm(
        {
          action: 'account/activateCard',
          success: (result) => {
            _this.cardPin = result;
            _this.activationSuccess = true;
          },
        },
        {
          activation_code: this.activationCode,
          card_public_token: this.publicToken,
        },
      );
    },
    closeActivateCardModal() {
      this.execute(
        {
          action: 'account/getAccount',
        },
        null,
      );
      this.clearError();

      // Try catch required because may be not present because of the v-if
      try {
        this.activationCode = null;
        this.cardPin = '';
        this.showNip = false;
        this.activationSuccess = false;
        this.$refs.activateForm.validationReset();
      } catch (err) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.nip-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nip-text {
  font-weight: 600;
  font-size: 1.1875rem;
}
</style>
