import axios from 'axios';
import errorTypes from '@/utils/error/error-types';
import _get from 'lodash/get';

function isUnauthorized(status) {
  return status === 401;
}

function isForbidden(status) {
  return status === 403;
}

function isMethodNotAllowed(status) {
  return status === 405;
}

function isBadRequest(status) {
  return status === 400;
}

function isValidationError(status, code) {
  return isBadRequest(status) && code === 'validation.error';
}

function isBlobErrorResponseType(error) {
  return _get(error, 'config.responseType') === 'blob';
}

function isServerError(status) {
  return status >= 500;
}

export default {
  name: 'axios',
  init: async ({ Vue, app }) => {
    Vue.prototype.$axios = axios;

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.validateStatus = (status) => (status >= 200 && status < 300) || status === 404;

    axios.interceptors.request.use(async (config) => {
      if (Vue.prototype.$firebaseAuth && Vue.prototype.$firebaseAuth.currentUser) {
        const token = await Vue.prototype.$firebaseAuth.currentUser.getIdToken();
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      try {
        if (Vue.prototype.$axios.defaults.headers['x-hello-space']) {
          if (!config.params) {
            config.params = {};
          }
          config.params.space = Vue.prototype.$axios.defaults.headers['x-hello-space'];
        }
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (!error.response) {
          throw error;
        }

        let statusCode = error.response.status;

        if (isBlobErrorResponseType(error)) {
          // NOTE (SG) If error on some download binary data endpoint we presume we will receive JSON response and not a binary content for the error detail.
          error.response.data = JSON.parse(await error.response.data.text());
        }

        if (isUnauthorized(statusCode) || isForbidden(statusCode)) {
          throw new errorTypes.SystemError(error.response.status, error.response.data);
        }

        const errorCode = _get(error, 'response.data.error.code');

        if (isBadRequest(statusCode) && !isValidationError(statusCode, errorCode) && errorCode) {
          const httpErrorCode = `errors.http.${errorCode.replaceAll('.', '_')}`;

          if (app.i18n.t(httpErrorCode) === httpErrorCode) {
            // NOTE (SG) TO REMOVE WHEN GOOD
            // eslint-disable-next-line no-console
            console.log('axios-onRejected-missing-i18n', httpErrorCode, _get(error, 'response.data.error.detail'));
            rg4js('send', {
              error,
              customData: { source: 'axios-onRejected-missing-i18n', errorCode, errorDescription: _get(error, 'response.data.error.detail') },
            });
          }
        }

        if (isMethodNotAllowed(statusCode) || isValidationError(statusCode, errorCode) || isServerError(statusCode)) {
          const errorToThrow = new errorTypes.SystemError(error.response.status, error.response.data);

          // NOTE (SG) TO REMOVE WHEN GOOD
          // eslint-disable-next-line no-console
          console.log('axios-onRejected-standard-SystemError', errorCode, _get(error, 'response.data.error.detail'));
          rg4js('send', {
            error,
            customData: { source: 'axios-onRejected-standard-SystemError', errorCode, errorDescription: _get(error, 'response.data.error.detail') },
          });

          throw errorToThrow;
        }

        // NOTE (SG) TO REMOVE WHEN GOOD
        // eslint-disable-next-line no-console
        console.log('axios-onRejected-default-HttpError', error.response.status, JSON.stringify(error.response.data, null, 2));
        throw new errorTypes.HttpError(error.response.status, error.response.data);
      },
    );
  },
};
