<template>
  <div class="consumer-gift-card-payment-success">
    <div class="header">
      <h2>{{ $t('components.consumer_gift_card_payment_success.title') }}</h2>
      <div class="h-text-2">{{ $t('components.consumer_gift_card_payment_success.subtitle') }}</div>
    </div>

    <div class="order-action-box">
      <p class="h-label-1">{{ textAndOrderNumber }}</p>

      <div v-if="!voucherReady" class="voucher-not-ready-container">
        <v-progress-circular v-if="!pollTimeExceeded && !inError" :size="100" :width="7" indeterminate color="primary" />
        <h-btn v-else :to="{ name: 'my-account' }">{{ $t('components.consumer_gift_card_payment_success.redirect_consumer_gifts') }}</h-btn>
      </div>

      <div v-else>
        <h-action-layout style="flex-direction: row" v-if="orderIsCompleted">
          <consumer-gift-card-download-pdf-modal :voucher-id="voucherId" :activation-code-id="activationCodeId">
            <template v-slot:activator="{ on, attrs }">
              <h-btn outlined large v-on="on" v-bind="attrs"><v-icon left>mdi-wallet-giftcard</v-icon>{{ $t('components.consumer_gift_card_download_pdf_modal.action_title') }}</h-btn>
            </template>
          </consumer-gift-card-download-pdf-modal>
          <consumer-gift-card-send-by-email-modal :voucher-id="voucherId">
            <template v-slot:activator="{ on, attrs }">
              <h-btn outlined large v-on="on" v-bind="attrs"><v-icon left>mdi-email-outline</v-icon>{{ $t('components.consumer_gift_card_send_by_email_modal.action_title') }}</h-btn>
            </template>
          </consumer-gift-card-send-by-email-modal>
        </h-action-layout>

        <h-btn class="download-receipt" large inverted :loading="receiptWorking" v-show="orderIsCompleted" @click="downloadReceipt">
          <v-icon size="20" center class="mr-2">mdi-open-in-new</v-icon>{{ $t('components.consumer_gift_card_payment_success.download_receipt') }}
        </h-btn>
      </div>

      <p class="h-text-2" v-if="orderIsCompleted">
        {{ $t('components.consumer_gift_card_payment_success.cta_my_account') }}<i>{{ $t('components.consumer_gift_card_payment_success.cta_my_account_italic') }}</i>
      </p>

      <p v-else class="h-text-2 pending-order-information">
        {{ $t('components.consumer_gift_card_payment_success.cta_my_account_pending_order') }}<i>{{ $t('components.consumer_gift_card_payment_success.cta_my_account_italic') }}</i>
      </p>

      <div class="link-section">
        <merchants-modal class="link-merchants" :offer_id="offerId">
          <template #activator="{ on, attrs }">
            <h-link class="link-merchants" large v-bind="attrs" v-on="on">{{ $t('components.consumer_gift_card_payment_success.cta_merchants') }}</h-link>
          </template>
        </merchants-modal>
        <h-link v-if="voucherReady" class="link-account" large :to="{ name: 'my-account' }" v-html="$t('components.consumer_gift_card_payment_success.cta_my_account_2')"></h-link>
        <h-link v-if="voucherReady" class="link-cancel" large @click="closeOverlay">{{ $t('components.consumer_gift_card_payment_success.cta_cancel') }}</h-link>
      </div>
    </div>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import MerchantsModal from '@/components/modals/MerchantsModal';
import ConsumerGiftCardSendByEmailModal from '../../consumer-gift-card/components/ConsumerGiftCardSendByEmailModal.vue';
import ConsumerGiftCardDownloadPdfModal from '@/components/consumer-gift-card/components/ConsumerGiftCardDownloadPdfModal.vue';

export default {
  name: 'purchase-consumer-gift-card-success',
  props: {
    offerId: {
      type: String,
    },
  },
  components: {
    ConsumerGiftCardDownloadPdfModal,
    MerchantsModal,
    ConsumerGiftCardSendByEmailModal,
  },
  async created() {
    if (this.canPoll) {
      this.doPoll = true;
    }
  },
  setup(props, { root }) {
    const { executeOverlay } = safeExecute(root.$store);
    return {
      executeOverlay,
    };
  },
  data() {
    return {
      giftCardDownloadWorking: false,
      receiptWorking: false,
      sleepTimeMs: 5 * 1000,
      maxPollTimeMs: 5 * 60 * 1000,
      pollTimeMs: 0,
      isPolling: false,
      doPoll: false,
      timeoutId: null,
      inError: false,
    };
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  watch: {
    canPoll() {
      if (this.canPoll) {
        this.doPoll = true;
      }
    },
    doPoll() {
      if (this.doPoll) {
        this.startPolling();
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('consumerGiftCard', ['voucherId', 'consumerGiftCardOrderId', 'orderIsCompleted', 'activationCodeId']),
    textAndOrderNumber() {
      return `${this.$t('components.consumer_gift_card_payment_success.order_id')} ${this.consumerGiftCardOrderId}`;
    },
    canPoll() {
      return this.consumerGiftCardOrderId !== null;
    },
    pollTimeExceeded() {
      return this.pollTimeMs > this.maxPollTimeMs;
    },
    shouldAutoPoll() {
      return this.canPoll && !this.voucherReady && !this.pollTimeExceeded;
    },
    voucherReady() {
      return this.orderIsCompleted && this.activationCodeId && this.voucherId;
    },
  },
  methods: {
    ...mapActions('overlay', ['closeHeaderDrawer']),
    closeOverlay() {
      this.closeHeaderDrawer();
    },
    async startPolling() {
      this.isPolling = true;
      this.doAutoPoll();
    },
    async doAutoPoll() {
      if (this.canPoll) {
        this.getConsumerGiftCardOrderStatus(this.autoPollCallback);
        this.pollTimeMs += this.sleepTimeMs;
      }
    },
    async autoPollCallback() {
      if (this.shouldAutoPoll && !this.inError) {
        this.timeoutId = setTimeout(() => {
          this.doAutoPoll();
          this.timeoutId = null;
        }, this.sleepTimeMs);
      } else {
        this.isPolling = false;
      }
    },
    async doManualPoll() {
      this.getConsumerGiftCardOrderStatus();
    },
    async getConsumerGiftCardOrderStatus(successCallback) {
      if (this.canPoll) {
        return this.executeOverlay(
          {
            action: 'consumerGiftCard/getOrderDetails',
            success: () => {
              if (successCallback) {
                successCallback();
              }
            },
            afterError: () => {
              if (this.timeoutId) {
                clearTimeout(this.timeoutId);
              }
              this.inError = true;
            },
          },
          this.consumerGiftCardOrderId,
        );
      }
    },
    async downloadReceipt() {
      this.receiptWorking = true;
      await this.executeOverlay(
        {
          action: 'consumerGiftCard/downloadReceipt',
          finally: () => {
            this.receiptWorking = false;
          },
        },
        {
          consumer_gift_card_order_id: this.consumerGiftCardOrderId,
          language: this.currentLocale,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-payment-success {
  display: block;
}

.header {
  margin-bottom: var(--gap);
}

.h-text-2 {
  margin-top: var(--gap-xsmall);
}

.order-action-box {
  border: var(--border-thin) solid var(--color-border);
  padding: var(--gap) var(--gap-page-side);
  margin-bottom: var(--gap);
}

.pending-order-information {
  margin-top: var(--gap);
}

.download-receipt {
  text-transform: revert;
  @media ($md-min) {
    margin-top: var(--gap-xsmall);
  }
}

.link-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap);
}

.v-btn.download-receipt {
  padding-left: 0px;
}

.voucher-not-ready-container {
  text-align: center;
  margin: var(--gap);
}
</style>
