<template>
  <h-form class="consumer-gift-card-amount-and-message" v-model="valid">
    <div class="amount-input">
      <h-pay-and-get-editable-input
        :label="$t('components.consumer_gift_card_amount_and_message.label')"
        :hint="$t('components.consumer_gift_card_amount_and_message.min_max', { min_amount: contextMinAmount, max_amount: contextMaxAmount })"
        v-model="amount"
        :rules="amountRules"
      />
    </div>
    <div class="message-input">
      <h-textarea
        v-model="message"
        :label="$t('components.consumer_gift_card_amount_and_message.msg_label')"
        :placeholder="$t('components.consumer_gift_card_amount_and_message.msg_placeholder')"
        persistent-hint
        persistent-placeholder
        height="140"
        maxlength="150"
        :rules="messageRules"
        counter
      />
    </div>
  </h-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validateMaxLength, validateMaxNumLines, validateMaxValue, validateMinValue, validatePositiveInteger, validateRequiredGivenFieldLabel } from '@/composables/validations';
import HPayAndGetEditableInput from '@/components/system/inputs/HPayAndGetEditableInput.vue';

export default {
  components: { HPayAndGetEditableInput },
  setup() {
    return {
      validateRequiredGivenFieldLabel,
      validateMaxNumLines,
      validateMaxLength,
      validateMinValue,
      validateMaxValue,
      validatePositiveInteger,
    };
  },
  name: 'consumer-gift-card-amount-and-message',
  data() {
    return {
      valid: false,
      messageRules: [this.validateMaxLength(150), this.validateMaxNumLines(6)],
    };
  },
  watch: {
    valid(val) {
      this.setAmountAndMessageAreValid(val);
    },
  },
  computed: {
    ...mapGetters('consumerGiftCard', ['contextDefaultAmount', 'contextMinAmount', 'contextMaxAmount', 'paymentAmount', 'giftCardMessage', 'hasAContextAndContextIsReady']),
    amountRules() {
      if (!this.hasAContextAndContextIsReady) {
        return () => [];
      }
      return () => [this.validateRequiredGivenFieldLabel(this.$t('components.consumer_gift_card_amount_and_message.label')), this.validateMinValue(this.contextMinAmount), this.validateMaxValue(this.contextMaxAmount), this.validatePositiveInteger()];
    },
    amount: {
      get() {
        return this.paymentAmount;
      },
      set(args) {
        this.setConsumerGiftCardPaymentAmount(args);
      },
    },
    message: {
      get() {
        return this.giftCardMessage;
      },
      set(args) {
        this.setGiftCardMessage(args);
      },
    },
  },
  methods: {
    ...mapActions('consumerGiftCard', ['setAmountAndMessageAreValid', 'setConsumerGiftCardPaymentAmount', 'setGiftCardMessage']),
  },
};
</script>

<style lang="scss" scoped>
.h-pay-and-get-editable-input {
  margin-bottom: var(--gap);
}

.amount-input {
  display: flex;
  justify-content: space-around;
}

.consumer-gift-card-amount-and-message {
  display: block;
  padding: var(--gap-large);
  border: var(--border-thin) solid var(--color-border);
  margin: var(--gap-large) 0;

  @media ($sm-min) {
    display: flex;

    flex-wrap: wrap;
    justify-content: left;
    & > .amount-input {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 1;
    }
    & > .message-input {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  @media ($md-min) {
    margin: var(--gap) 0;
    gap: var(--gap);
  }
}
</style>
