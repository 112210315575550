import {
  isBonusOffer,
  isGiftCertificateOffer,
  isSinglePublisherOffer,
  isMultiplePublisherOffer,
  offerStatusIsOneOf,
  consumerIsEnabled,
  activationButtonIsHidden,
  purchaseButtonIsHidden,
  isConsumerInventoryDepleted,
  noCloseLoop,
} from '@/composables/offer-state-informer';

export const CallbackType = {
  SEE_STORES: 'see_stores',
  SEE_OFFER: 'see_offer',
  BUY_OFFER: 'buy_offer',
  ACTIVATE_VOUCHER: 'activate_voucher',
  BUY_AS_GIFT: 'buy_as_gift',
};

const { SEE_STORES, SEE_OFFER, BUY_OFFER, ACTIVATE_VOUCHER, BUY_AS_GIFT } = CallbackType;

const actions = Object.freeze({
  see_stores_link: {
    callback_type: SEE_STORES,
    label: 'components.offer_small_card.see_stores_link',
  },
  see_offer_button: {
    callback_type: SEE_OFFER,
    label: 'components.offer_small_card.see_offer_button',
  },
  buy_now_by_drawer_button: {
    label: 'components.offer_small_card.buy_now_button',
    callback_type: BUY_OFFER,
  },
  activate_voucher: {
    callback_type: ACTIVATE_VOUCHER,
    label: 'components.offer_small_card.activate_voucher',
  },
  activate_voucher_detail_button: {
    callback_type: SEE_OFFER,
    label: 'components.offer_small_card.activate_voucher',
  },
  buy_as_gift_button: {
    callback_type: BUY_AS_GIFT,
    label: 'components.offer_small_card.buy_as_gift_button',
  },
  see_offer_link: {
    callback_type: SEE_OFFER,
    label: 'components.offer_small_card.see_offer_link',
  },
  buy_now_by_offer_details_button: {
    callback_type: SEE_OFFER,
    label: 'components.offer_small_card.buy_now_button',
  },
});

// NOTE (PLM) publisher and offer have their respective actions register, they also have their respective translations
export function extractOfferDisplayInfos(offer) {
  let displayPermissions = [];

  if (isBonusOffer(offer)) {
    if (isSinglePublisherOffer(offer) && noCloseLoop(offer) && offerStatusIsOneOf(offer, ['available'])) {
      addActionIfConditionTrue('see_offer_button', true);
      return displayPermissions;
    }
    addActionIfConditionTrue('see_stores_link', isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out', 'coming_soon', 'closed']));
    addActionIfConditionTrue('see_offer_button', offerStatusIsOneOf(offer, ['sold_out', 'coming_soon', 'closed']));
    addActionIfConditionTrue('buy_now_by_drawer_button', isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available']));
    addActionIfConditionTrue('buy_now_by_offer_details_button', !isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available']));
    return displayPermissions;
  }

  if (isGiftCertificateOffer(offer) && !consumerIsEnabled(offer)) {
    if (isSinglePublisherOffer(offer) && noCloseLoop(offer)) {
      addActionIfConditionTrue('see_offer_button', true);
      return displayPermissions;
    }
    addActionIfConditionTrue('see_stores_link', !activationButtonIsHidden(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('see_offer_button', activationButtonIsHidden(offer) || offerStatusIsOneOf(offer, ['coming_soon', 'closed']));
    addActionIfConditionTrue('activate_voucher', isSinglePublisherOffer(offer) && !activationButtonIsHidden(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('activate_voucher_detail_button', !isSinglePublisherOffer(offer) && !activationButtonIsHidden(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    return displayPermissions;
  }

  if (isGiftCertificateOffer(offer) && consumerIsEnabled(offer)) {
    if (isSinglePublisherOffer(offer) && noCloseLoop(offer)) {
      addActionIfConditionTrue('see_offer_button', true);
      return displayPermissions;
    }
    addActionIfConditionTrue('see_stores_link', isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('activate_voucher', isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('activate_voucher_detail_button', !isSinglePublisherOffer(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('see_stores_link', isMultiplePublisherOffer(offer) && !purchaseButtonIsHidden(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('buy_as_gift_button', !purchaseButtonIsHidden(offer) && !isConsumerInventoryDepleted(offer) && offerStatusIsOneOf(offer, ['available', 'sold_out']));
    addActionIfConditionTrue('see_offer_button', purchaseButtonIsHidden(offer) || isConsumerInventoryDepleted(offer) || offerStatusIsOneOf(offer, ['coming_soon', 'closed']));
    return displayPermissions;
  }

  return displayPermissions;

  function addActionIfConditionTrue(actionKey, conditions) {
    if (conditions) {
      displayPermissions.push(actions[actionKey]);
    }
  }
}
