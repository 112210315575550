import _map from 'lodash/map';

function defaultImage() {
  return '/img/default_space_banner.png';
}
export function backgroundImageHelper(originalUrl, dpi) {
  return originalUrl ? `url(${originalUrl}?tr=n-hp_background)` : '';
}

export function bigHeroImageHelper(originalUrl, dpi) {
  return originalUrl ? `${originalUrl}?tr=n-hp_big_hero` : defaultImage();
}
export function smallHeroImageHelper(originalUrl, dpi) {
  return originalUrl ? `${originalUrl}?tr=n-hp_small_hero` : defaultImage();
}

export function showcaseImageHelper(originalUrl, dpi) {
  return originalUrl ? `${originalUrl}?tr=n-hp_showcase_card` : defaultImage();
}

export function carouselImagesHelpers(originalUrls) {
  return originalUrls.length !== 0 ? _map(originalUrls, (url) => `${url}?tr=n-hp_showcase_carousel`) : [defaultImage()];
}

export function horizontalLogoImageHelper(originalUrl) {
  return originalUrl ? `${originalUrl}` : defaultImage();
}

export function showcaseSmall(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_showcase_small_v2` : defaultImage();
}

export function spaceShowcaseImageHelper(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_showcase_card` : defaultImage();
}

export function spaceWidgetImageHelper(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_space_widget_v3` : defaultImage();
}

export function mobileHeroImageHelper(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_recently_visited_v2` : defaultImage();
}

export function recentlyVisitedImageHelper(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_recently_visited_v2` : defaultImage();
}

export function marketingCategoryImageHelepr(originalUrl) {
  return originalUrl ? `${originalUrl}?tr=n-hp_mall_category` : defaultImage();
}
