import { render, staticRenderFns } from "./SmallCardBase.vue?vue&type=template&id=2a2ebe9f&scoped=true"
import script from "./SmallCardBase.vue?vue&type=script&lang=js"
export * from "./SmallCardBase.vue?vue&type=script&lang=js"
import style0 from "./SmallCardBase.vue?vue&type=style&index=0&id=2a2ebe9f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2ebe9f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VIcon})
