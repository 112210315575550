<template>
  <small-card-base
    :restricted-to-members="offer.features.restricted_to_members"
    :image="offer.offer_branding.images.showcases[currentLocale][0]"
    :show-panier-bleu="offer.offer_branding.features.panier_bleu"
    :url="offerUrl"
    :offer-title="offer.offer_branding.label[currentLocale]"
  >
    <template #chip>
      <h-chip-small
        :plus-action="plus"
        :minus-action="minus"
        :pay="payAmount | currency | currencySymbol"
        :obtain="offerObtain | currency | currencySymbol"
        :disable-plus="disablePlus(payAmount, offer.incentive.step, offer.incentive.user_payment_limit, amountLeft)"
        :disable-minus="disableMinus(payAmount, offer.incentive.step, offer.incentive.minimum_payment, amountLeft)"
      />
    </template>

    <template #content>
      <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />
      <h-progress-bar
        class="progress-bar"
        :font-size="13"
        :status="offer.status"
        :start="offer.availability.start_date"
        :expiration="offer.availability.end_date"
        :current-number="offer.availability.inventory.adjusted_avail_payment_amount"
        :total-number="offer.availability.inventory.adjusted_total_payment_amount"
        :min-purchase="offer.incentive.minimum_payment"
        :max-purchase="offer.incentive.adjusted_user_payment_limit"
      />
    </template>

    <template #call-to-action>
      <small-card-base-cta-link v-for="(action, index) in allowedActions.slice(0, -1)" :text="$t(action.label)" :key="index" @click="executeActionByCode(action.callback_type)" />
      <h-btn v-if="allowedActions.length > 0" class="mt-5" color="var(--color-branding)" block large @click="executeActionByCode(_last(allowedActions).callback_type)">{{ $t(_last(allowedActions).label) }}</h-btn>
    </template>
  </small-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseCtaLink from '@/components/cards/small/SmallCardBaseCtaLink.vue';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount, totalAmount } from '@/composables/offer-publisher-helper';
import _get from 'lodash/get';
import _last from 'lodash/last';
import { CallbackType, extractOfferDisplayInfos } from '@/composables/offer-action-display-selector';
import safeExecute from '@/composables/safe-execute';

export default {
  setup(props, { root }) {
    const { execute, executeContent, working } = safeExecute(root.$store);
    return {
      remainingAmount,
      totalAmount,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
      execute,
      executeContent,
      working,
    };
  },
  components: { SmallCardBase, SmallCardBaseBadge, SmallCardBaseCtaLink },
  name: 'bonus-offer-single-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
  },
  data() {
    return {
      payAmount: this.offer.incentive.default_payment,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId']),
    offerObtain() {
      return (this.offer.incentive.bonus_percent + 1) * this.payAmount;
    },
    amountLeft() {
      return this.remainingAmount(
        this.offer.availability.inventory.total_amount,
        this.offer.availability.inventory.used_amount,
        this.offer.incentive.bonus_display,
        _get(this.offer, 'publisher_inventory.total_amount', undefined),
        _get(this.offer, 'publisher_inventory.used_amount', undefined),
      );
    },
    offerUrl() {
      if (this.offer.offer_branding.url && this.offer.offer_branding.url[this.currentLocale]) {
        return this.offer.offer_branding.url[this.currentLocale];
      }
      return '';
    },
    allowedActions() {
      return extractOfferDisplayInfos(this.offer);
    },
  },
  methods: {
    ...mapActions('payment', ['setCurrentPaymentAmount', 'setPaymentAmountForPurchase']),
    executeActionByCode(callback_type) {
      switch (callback_type) {
        case CallbackType.SEE_STORES:
          this.seeOfferAction();
          break;
        case CallbackType.SEE_OFFER:
          this.seeOfferAction();
          break;
        case CallbackType.BUY_OFFER:
          this.buyNowAction();
          break;
        default:
          break;
      }
    },
    seeOfferAction() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.currentSpaceSlug, offer_id: this.offer.id, _position: { x: 0, y: window.scrollY } } });
    },
    async buyNowAction() {
      await this.executeContent(
        {
          action: 'overlay/openHeaderDrawerForPayment',
          afterError: (err) => {
            this.$emit('buyNowError', err);
          },
        },
        {
          payload: {
            offer_id: this.offer.id,
            offer_restricted_to_member: this.offer.features.restricted_to_members || false,
            publisher_id: this.offer.publisher_id,
            partner_id: this.currentPartnerId,
          },
        },
      );

      this.setCurrentPaymentAmount(this.payAmount);
      this.setPaymentAmountForPurchase(this.payAmount);
    },
    plus() {
      this.payAmount = this.addAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    minus() {
      this.payAmount = this.removeAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    _last,
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  margin-bottom: 52px;
}
</style>
