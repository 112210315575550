<template>
  <fragment>
    <pay-and-get-offer v-if="offer" :branding="branding" :restricted="restricted">
      <template #extraContent>
        <div style="width: 100%">
          <merchants-modal class="link-merchants" :offer_id="offer.id">
            <template #activator="{ on, attrs }">
              <h-link class="link-merchants" large v-bind="attrs" v-on="on">{{ $t('components.consumer_gift_card_payment_success.cta_merchants') }}</h-link>
            </template>
          </merchants-modal>
        </div>
        <div style="width: 100%">
          <consumer-gift-card-info-modal :show-action="false" :large-link="true" />
        </div>
      </template>
    </pay-and-get-offer>
    <consumer-gift-card-amount-and-message />
    <auth-info-box class="mt-5" />
    <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" style="width: -webkit-fill-available" class="mt-5" />
    <auth-and-register v-else class="mt-5" />
  </fragment>
</template>

<script>
import _get from 'lodash/get';
import ConsumerGiftCardInfoModal from '../../consumer-gift-card/components/ConsumerGiftCardInfoModal.vue';
import MerchantsModal from '../../modals/MerchantsModal.vue';
import PayAndGetOffer from '../../pay-and-get/PayAndGetOffer.vue';
import ConsumerGiftCardAmountAndMessage from '../../consumer-gift-card/components/ConsumerGiftCardAmountAndMessage.vue';
import AuthInfoBox from '../../authentication/AuthInfoBox.vue';
import AuthAndRegister from '../../authentication/AuthAndRegister.vue';
import SwapAuthAndRegister from '../../authentication/SwapAuthAndRegister.vue';

export default {
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
  },
  components: { PayAndGetOffer, MerchantsModal, ConsumerGiftCardInfoModal, ConsumerGiftCardAmountAndMessage, AuthInfoBox, SwapAuthAndRegister, AuthAndRegister },
  name: 'purchase-consumer-gift-card-auth',
  computed: {
    branding() {
      return this.offer.offer_branding || {};
    },
    restricted() {
      return _get(this.offer, 'features.restricted_to_members', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-merchants {
  margin: var(--gap) 0 var(--gap-xsmall) 0;
}
</style>
