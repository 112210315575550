<template>
  <v-card v-on="$listeners" :to="toUrl" class="small-card-base">
    <div class="small-card-top-section" :style="{ backgroundImage: 'url(' + showcaseImageHelper(image) + ')' }">
      <slot name="chip"></slot>

      <restricted-to-members-badge v-if="restrictedToMembers" class="restricted-to-members-badge-on-top-left" />

      <div v-if="showVoucherChip" class="voucher-chip">{{ $t('components.small_card.voucher') }}</div>

      <a v-if="url" class="publisher-link" :href="url" target="_blank" :aria-label="$t('components.small_card.external_website')">
        <v-icon color="white">mdi-open-in-new</v-icon>
      </a>

      <img v-if="showPanierBleu" class="panier-bleu" src="@/assets/panierbleu_htal_vignette_faisonsrouler_rgb.svg" :alt="$t('components.small_card.panier_bleu')" />
    </div>

    <div class="small-card-content">
      <div v-if="offerTitle" class="title-container">
        <span class="title-text">{{ offerTitle }}</span>
      </div>

      <slot name="content"></slot>

      <div class="cta-section">
        <slot name="call-to-action"></slot>
      </div>
    </div>
  </v-card>
</template>
<script>
import { showcaseImageHelper } from '@/composables/image-helper';
import RestrictedToMembersBadge from '@/components/utilities/RestrictedToMembersBadge.vue';

export default {
  components: { RestrictedToMembersBadge },
  setup() {
    return { showcaseImageHelper };
  },
  name: 'small-card-base',
  props: {
    image: {
      type: String,
      default: undefined,
    },
    offerTitle: {
      type: String,
      default: undefined,
    },
    offerDescription: {
      type: String,
      default: undefined,
    },
    showVoucherChip: {
      type: Boolean,
      default: false,
    },
    showPanierBleu: {
      type: Boolean,
      default: false,
    },
    restrictedToMembers: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: undefined,
    },
    toUrl: {
      type: String,
      default: undefined,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.restricted-to-members-badge-on-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.v-card.small-card-base {
  display: flex;
  flex-flow: column;
  box-shadow: var(--card-shadow) !important;
  height: 100%;
}

.small-card-top-section {
  position: relative;

  // This is the future
  //aspect-ratio: 1 / 0.490683;

  // Remove this padding-top property when you are no longer in the past.
  padding-top: 49.0683%;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .h-chip-small {
  position: absolute;
  right: 15px;
  top: 20px;
}

.voucher-chip {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 15px 12px;
  border-radius: 9px;
  background-color: var(--color-branding);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125;
  color: white;
  text-transform: uppercase;
}

.panier-bleu {
  position: absolute;
  left: 20px;
  bottom: -5px;
  height: 30px;
}

.publisher-link {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  text-decoration: none !important;
}

.small-card-content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding: 20px;
  margin: 0px;
  width: 100%;
}

.cta-section {
  margin-top: auto;
}

.title-container {
  display: flex;
  align-items: center;
  height: 67px;
  margin: 0 0 20px 0;
  font-weight: 400;
}

.title-text {
  font-size: 28px;
  line-height: 1.2;
  color: var(--color-branding);

  // Cut after 2 line with ...
  @include cut-after-x-line(2);
}
</style>
