module.exports = {
  components: {
    about_overlay: {
      condition_link: "Conditions d'utilisation",
      copyright:
        "La carte prépayée Hello Visa* est émise par la Compagnie de Fiducie Peoples, en vertu d'une licence de Visa Int. *Marque de commerce de Visa International Service Association et utilisée sous licence de la Compagnie de Fiducie Peoples. © {year} HELLO NETWORK INC. TOUS DROITS RÉSERVÉS.",
      hello_card_link: 'Carte Hello',
      merchant_link: 'Entreprises et commerçants',
      politics_link: 'Politique de vie privée',
      step_1: {
        image: 'about_step_3_fr.png',
        sub_text: "Tous les soldes que vous obtenez sont déposés sur votre carte Hello qui est rechargeable. Une fois que vous l'avez, vous la gardez pour continuer d'en profiter.",
        text: 'Avez-vous reçu une carte Hello?',
      },
      step_2: {
        image: 'about_step_4_fr.png',
        sub_text: "C'est une carte Visa, payez vos achats comme vous le faites avec votre carte de crédit ou de débit habituelle. C'est simple, rapide et discret.",
        text: 'Utilisez votre carte Hello dans les commerces admissibles.',
      },
    },
    account_card_required: {
      action: 'Obtenir ma carte',
      action_mobile: 'Obtenir ma carte',
      message: 'Vous devez procéder à la demande de votre carte Hello Visa.',
      sub_message: 'Tous vos soldes y seront transférés automatiquement et vous pourrez les utiliser en magasin avec la carte comme vous le faites normalement avec une carte de crédit ou de débit.',
    },
    account_email_pending_verification: {
      action: 'Envoyer de nouveau un courriel de vérification',
      action_mobile: 'Ré-envoyer un courriel de vérification',
      message: 'Vous devez vérifier votre adresse courriel',
      modal_message_part_1: 'Un courriel de vérification a été envoyé à {email}. ',
      modal_message_part_2: "N'oubliez pas de vérifier votre dossier d'indésirables si vous ne recevez pas le courriel.",
      modal_title: 'Envoyé!',
      sub_message: "Un courriel avec un lien de vérification vous a été envoyé. N'oubliez pas de vérifier votre dossier d'indésirables ou tout autre dossier secondaire de votre application de gestion de courrier.",
    },
    account_info_bar_activate_card: {
      action_title: 'Activer',
      modal_action: 'Activer',
      modal_action_show_pin: 'Révéler mon nip',
      modal_input_hint: '6 chiffres',
      modal_input_label: "Code d'activation",
      modal_need_pin: 'Vous aurez besoin de votre NIP!',
      modal_success_text: 'Votre carte a été activée avec succès!',
      modal_text: "Lorsque vous recevez votre carte par la poste, l'enveloppe contient une lettre de bienvenue sur laquelle est imprimé le code d'activation à 6 chiffres.",
      modal_title: 'Activer votre carte',
    },
    account_info_bar_base: {
      my_application_id: 'Ma demande de carte Hello',
      my_card: 'Ma carte Hello',
      my_money: 'Mon argent',
      status: 'Statut',
      status_value: {
        active: 'Active',
        approved: 'Approuvée',
        blocked: 'Bloquée',
        deactivated: 'Désactivée',
        expired: 'Expirée',
        locked: 'Verrouillée',
        lost_stolen: 'Perdue / volée',
        pending: "En attente d'approbation",
        pending_activation: "En attente d'activation",
        rejected: 'Rejetée',
        unknown: '---',
      },
    },
    account_info_bar_card: {
      action_title: 'Gérer',
      modal_list_1: 'Avoir accès à vos soldes en tout temps',
      modal_list_2: 'Ajouter des fonds à votre compte Mon argent',
      modal_list_3: 'Obtenir votre NIP de carte si votre mémoire vous joue des tours',
      modal_list_4: 'Recevoir des notifications push lorsque vous faites des transactions avec votre carte',
      modal_list_5: 'Et beaucoup plus!',
      modal_store: 'Ou recherchez “hellocard”  dans les Stores',
      modal_sub_title: "Téléchargez l'application mobile compagnon de votre carte Hello pour :",
      modal_title: 'Gérer votre carte',
      renew_action: {
        title: 'Renouveler',
      },
    },
    account_space_consumer_gift_card_header: {
      title: 'Mes commandes de cartes-cadeaux',
    },
    account_space_header: {
      img_alt: "Logo de l'espace",
      see_offers: 'Voir les offres',
      website: 'Visiter le site web',
    },
    account_space_section: {
      empty: "Vous n'avez aucune offre sur cette carte",
    },
    account_voucher: {
      actual_balance: 'Solde actuel',
      bonus: 'Bonification',
      bonus_expiration: 'Expire le {date}',
      bonus_hints: {
        link_title: 'Comment ça marche?',
        message: "Le montant de la bonification expirera le {date}. Une fois la bonification expirée, seul le montant restant de la contribution peut être utilisé dans le ou les commerce(s) de l'offre.",
        title: 'Bonification',
      },
      contribution: 'Contribution',
      contribution_hints: {
        link_title: 'Comment ça marche?',
        message:
          "Le montant de la contribution n'expire jamais et vous ne perdez jamais cet argent, il vous appartient. Cependant, la portion inutilisée au {date} sera transférée à cette date dans votre compte <i>Mon argent</i>.<br/><br/>Les fonds du compte <i>Mon argent</i> peuvent être utilisés avec la carte Hello dans n'importe quel point de vente acceptant les cartes Visa au Canada (à l'exception des jeux de hasard, de l'essence à la pompe et des services monétaires).",
        title: 'Contribution',
      },
      dormancy: {
        dormancy_hints: {
          link_title: 'Comment ça marche?',
          message: "Après {delay} mois d'inactivité, des frais d'inactivité de {amount} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
          title: 'Inactivité',
        },
        dormancy_sub_text: "Frais d'inactivité à partir de {date}",
      },
      history: 'Historique',
      merchants_link: 'Voir les commerces',
      merchants_modal_title: 'Liste des commerces admissibles',
      my_money_transfer: 'Transfert à Mon argent le {date}',
      transfer_hints: {
        link_title: 'Comment ça marche?',
        message:
          "Le montant n'expire jamais et vous ne perdez jamais cet argent, il vous appartient. Cependant, la portion inutilisée au {date} sera transférée à cette date dans votre compte <i>Mon argent</i>.<br/><br/>Les fonds du compte <i>Mon argent</i> peuvent être utilisés avec la carte Hello dans n'importe quel point de vente acceptant les cartes Visa au Canada (à l'exception des jeux de hasard, de l'essence à la pompe et des services monétaires).",
        title: 'Transfert',
      },
    },
    account_voucher_base: {
      canceled: 'Annulé',
      img_alt: 'Image de la carte-cadeau',
      transferred: 'Transféré',
    },
    account_voucher_consumer_gift_card: {
      img_alt: 'Image de la carte-cadeau',
      merchants_link: 'Voir les commerces',
      merchants_modal_title: 'Liste des commerces admissibles',
    },
    account_voucher_consumer_gift_card_amount: {
      activation_code_status: {
        active: "en attente d'activation",
        canceled: 'annulé',
        redeemed: 'activé',
        undefined: 'non disponible',
      },
      actual_balance: 'Montant',
      consumer_gift_card_download_pdf_modal_action_title: 'Télécharger la carte-cadeau',
      consumer_gift_card_payment_success_download_receipt: 'Consulter votre reçu',
      consumer_gift_card_send_by_email_modal_action_title: 'Envoyer par courriel',
      send_email_status: {
        error: "erreur avec l'envoie du courriel",
        none: 'courriel non envoyé',
        sent: 'courriel envoyé',
        undefined: "status de l'envoie du courriel non disponible",
      },
      status: 'Status',
    },
    activate_card_step_form: {
      allow_communication: "Permettre à {partner} de m'envoyer des communications et promotions par courriel",
      and: 'et à',
      card_activated_modal: {
        btnText: 'Ok',
        pin_error: {
          text: "Essayez de l'obtenir en téléchargeant l'application mobile Hello à la fin du processus d'activation de votre carte. Si vous avez besoin d'aide, n'hésitez pas à contacter notre service à la clientèle à support@hellocard.ca.",
          title: "Votre carte a été activée, mais nous ne sommes pas en mesure de vous fournir le NIP de votre carte en raison d'une erreur inconnue.",
        },
        pin_label: 'Le NIP de votre carte',
        pin_text: 'Pour les achats avec puce et NIP, vous aurez besoin du NIP de votre carte.',
        reassuring_text: "Vous pouvez toujours obtenir un rappel du NIP de votre carte dans l'application mobile Hello.",
        title: 'Votre carte a été activée avec succès!',
      },
      cardholder_agreement: "l'Entente du titulaire de carte",
      consent_label: 'Je consens à',
      country_label: 'Pays',
      date_of_birth_label: 'Date de naissance',
      electronic_communications_agreement: "l'Entente sur les communications électroniques",
      error_modal: {
        btnText: 'Ok',
        text: "Veuillez essayer de nouveau ou contactez notre service à la clientèle à support@hellocard.ca en utilisant votre identifiant de carte à 9 chiffres imprimés à l'endos de votre carte.",
        title: 'Une erreur est survenue.',
      },
      first_name_label: 'Prénom',
      next: 'Activer',
      postal_code_label: 'Code postal',
    },
    activation_flow_info: {
      header: {
        info_text: "Votre compte Hello vous permet d'accéder à vos achats, à vos soldes et à notre service clientèle.",
        log_in_text: 'Connectez-vous pour activer votre carte',
        profile_alt_text: "Icône de profil d'utilisateur",
      },
      step_1: {
        image_alt_text: "Icône de formulaire d'inscription vide",
        info_text: 'Connectez-vous ou inscrivez-vous ci-dessous.',
      },
      step_2: {
        image_alt_text: "Icône d'un formulaire rempli, censuré, contenant les détails de la carte de crédit",
        info_text: 'Saisissez les informations relatives à votre carte.',
      },
      step_3: {
        image_alt_text: 'Icône de la liste des commerces',
        info_text: 'Recevez le NIP de votre carte et la liste des commerces admissibles.',
      },
      step_4: {
        image_alt_text: 'Icône de lecteur de carte de crédit',
        info_text: 'Dépensez votre solde avec votre carte Hello dans les commerces admissibles.',
      },
    },
    activation_view: {
      back: "Retour à l'offre",
      cta_instant_card: "J'ai reçu une carte Hello",
      cta_redeem: "J'ai reçu un code d'activation",
      subtitle: 'Veuillez choisir le cas applicable',
      title: 'Activer ma carte-cadeau',
    },
    app: {
      uncaught_error_modal_message: 'Une erreur inconnue est survenue. Veuillez svp réessayer ou contacter le service à la clientèle de Hello à support@hellocard.ca.',
      uncaught_error_modal_title: 'Erreur',
    },
    app_stores_badges: {
      download_apple: "Télécharger sur l'App Store",
      download_google: 'Télécharger sur Google Play',
    },
    auth_form: {
      action: "S'authentifier",
      dont_have_account: {
        1: "Vous n'avez pas encore de compte Hello? ",
        2: 'Créez votre compte',
        3: " maintenant et dites Hello à plus d'argent.",
      },
      email_field: 'Adresse courriel',
      forgot_password_action: "J'ai oublié mon mot de passe",
      password_field: 'Mot de passe',
      title: 'Se connecter à Hello',
    },
    auth_info_box: {
      subtitle: 'Vous compte Hello vous donne accès à vos achats, à vos soldes et à notre service à la clientèle.',
      title: 'Connectez-vous pour continuer',
    },
    big_card: {
      panier_bleu: "Panier Bleu - Faisons rouler l'économie d'ici",
      voucher: 'Carte-cadeau',
    },
    big_card_base_status_label: {
      closed: "L'offre est terminée",
      coming_soon: 'En vente dès le {date} {hour}',
      coming_soon_no_date: 'En vente bientôt',
      expiration: "L'offre expire le {date}",
      sold_out: "L'offre est épuisée",
    },
    bonus_offer_publisher_big_card: {
      call_to_action: 'Acheter Maintenant',
      progress_left: '{currentNumber} disponible',
    },
    bonus_offer_publisher_small_card: {
      progress_left: '{currentNumber} disponible',
    },
    bonus_offer_single_publisher_big_card: {
      call_to_action: 'Acheter Maintenant',
    },
    bonus_offer_terms_and_conditions_modal: {
      action_title: 'Ok',
      consent_text: 'En procédant au paiement, vous consentez aux',
      consent_text_link: 'Termes et conditions de vente.',
      modal_sub_title: 'En procédant à l’achat et au paiement de la commande, vous consentez aux termes et conditions de vente indiqués ci-dessous:',
      modal_text1: 'La carte-cadeau n’est pas remboursable.',
      modal_text2: 'La carte-cadeau sera déposée sur votre carte Hello sous forme de solde et ne peut être échangée contre de l’argent en espèces.',
      modal_text3: 'Suite à l’achat, vous devrez procéder à la demande de votre carte Hello, une carte vous sera acheminée par la poste dans les 7 à 10 jours ouvrables.',
      modal_text4: 'Le solde peut être consulté en tout temps sur espacehello.ca ou dans l’appli mobile Hello.',
      modal_text5: 'Le solde obtenu peut être utilisé seulement dans les commerces admissibles au programme de la carte-cadeau.',
      modal_text6: 'La liste des commerces admissibles n’est pas finale et peut varier occasionnellement.',
      modal_text7: "Après {dormancyMonths} mois d'inactivité, des frais d'inactivité de {dormancyFixedFee} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
      modal_text7_with_extension:
        "Après {dormancyMonths} mois d'inactivité, des frais d'inactivité de {dormancyFixedFee} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé, sauf si vous avez fait une demande à support@hellocard.ca avant la fin du {dormancyMonths}e mois, auquel cas aucun frais d'inactivité ne sera appliqué jusqu'au {dormancyExtendedMonths}e mois suivant la date d'achat.",
      modal_text8:
        "Réseau Hello n’est pas responsable des fonds achetés autre que d’en donner l’accès par l’utilisation de la plateforme Hello. Si le partenaire affilié faisant la promotion du programme cesse d'exercer ses activités ou d'honorer vos fonds, votre seul recours en matière de remboursement de la valeur payée est directement contre ce partenaire affilié ou son représentant en insolvabilité.",
      modal_title: 'Termes et conditions de vente',
    },
    card_application: {
      redirect_link: {
        return_to_account_label: 'Revenir à mon compte',
        return_to_home_label: "Revenir à la page d'accueil",
        return_to_offer_label: 'Revenir à l’offre',
        return_to_space_label: "Revenir à l'espace",
      },
    },
    card_selection: {
      activation_renew_required: 'Suite à votre activation, vous obtiendrez les instructions pour renouveler votre carte.',
      card_expired: 'Expirée',
      card_lost_stolen: 'Perdue ou volée',
      card_type_caption_application: 'Processus de demande en cours',
      card_type_label_application: 'Carte Hello à votre nom',
      card_type_label_gpr: 'Carte Hello à votre nom',
      card_type_label_instant: 'Carte Hello Instantanée',
      purchase_renew_required: 'Suite à votre achat, vous obtiendrez les instructions pour renouveler votre carte.',
    },
    card_summary_desktop: {
      classic_card_fine_print: '18 ans et plus. Requiert une vérification d’identité (automatisée).',
      classic_card_label: 'Meilleure expérience client',
      classic_card_submit: 'Choisir',
      classic_card_title: 'Hello Classique',
      instant_card_submit: 'Choisir',
      instant_card_title: 'Hello Instantanée',
    },
    card_type_selection_modal: {
      detail: "La carte Hello est une carte prépayée rechargeable. Ce n'est pas une carte de crédit et son utilisation n'affecte pas votre dossier de crédit.",
      headings: {
        limit: 'Limite pour les soldes',
        open_wallet: 'Portefeuille ouvert',
        open_wallet_hint:
          "C'est un portefeuille qui contient des fonds vous appartenant et que vous pouvez recharger par virement Interac ou par carte de débit Interac avec Apple Pay et Google Pay.<br/><br/>Ces fonds peuvent être utilisés dans n'importe quel commerce acceptant les cartes Visa au Canada (sauf quelques exceptions) et sont particulièrement utiles pour effectuer une transaction dans un commerce dont le montant excède le solde que vous possédez pour dépenser dans ce commerce.",
        personalized: 'Personnalisée à votre nom',
        pin: 'Choix du NIP de la carte',
        rechargeable: 'Rechargeable',
        replacement_cost: 'Coût de remplacement',
        shipping_time: 'Livraison (jours ouvrables)',
      },
      redirect_link: {
        return_to_offer_label: "Revenir à l'offre",
      },
      shipping_time_value: '7-10 jours',
      subtitle: 'Vous devez obtenir une carte Hello pour utiliser vos soldes dans les commerces admissibles. Choisissez la carte qui vous convient le mieux.',
      title: 'Choisir ma carte prépayée Hello',
    },
    change_password_view: {
      action: 'Modifier',
      password_label: 'Mot de passe',
      success_message: 'Le mot de passe a été modifié avec succès',
      title: 'Modifier votre mot de passe',
    },
    claim_gift_certificate_redeem: {
      action: 'Réclamer',
      activation_title: "Entrez le code d'activation de votre carte-cadeau",
      allow_communication: "Permettre à {partner} de m'envoyer des communications et promotions par courriel",
      label: "Code d'activation",
      select_card_title: 'Sélection de carte',
    },
    claim_gift_certificate_success: {
      sub_title: 'Votre compte a été mis à jour avec le nouveau solde!',
      title: 'La carte-cadeau a été réclamé avec succès!',
    },
    classic_card_summary: {
      fine_print: "18 ans et plus. Requiert une vérification d'identité (automatisée).",
      label: 'Meilleure expérience client',
      submit: 'Choisir',
      title: 'Hello Classique',
    },
    consumer_gift_card_amount_and_message: {
      label: 'Montant',
      min_max: 'Min. {min_amount}$, max. {max_amount}$',
      msg_label: 'Message personnalisé (optionnel)',
      msg_placeholder: 'Ex: Passe de joyeuses fêtes! - Laurie',
    },
    consumer_gift_card_amount_selector: {
      other: 'AUTRE',
    },
    consumer_gift_card_corporate_promotion: {
      cta: 'En savoir plus',
      text: 'Que ce soit pour récompenser vos employés ou pour célébrer une occasion importante, notre carte-cadeau corporative répondra assurément à vos besoins.',
      title: 'Pour une entreprise?',
    },
    consumer_gift_card_download_pdf_modal: {
      action_title: 'Télécharger la carte-cadeau',
      cancel_action: 'Annuler',
      download_action: 'Télécharger',
      download_error_message: 'Une erreur est survenue lors du téléchargement de votre fichier.',
      download_success_message: 'Votre fichier a été téléchargé avec succès !',
      filename: 'carte-cadeau-{activation_code_id}.pdf',
      language_radios: {
        english: {
          label: 'Anglais',
        },
        french: {
          label: 'Français',
        },
        title: 'Veuillez sélectionner la langue du PDF',
      },
      modal_title: 'Télécharger la carte-cadeau',
    },
    consumer_gift_card_faq: {
      activation: 'Avez-vous reçu une carte-cadeau?',
      activation_cta: ' Activez-la ici!',
      answer_1:
        "C'est une solution qui vous permet d’acheter, d’envoyer et de recevoir des cartes-cadeaux pour une variété de commerces et de restaurants admissibles. Les cartes-cadeaux doivent être activées et déposées sur une carte prépayée et rechargeable Hello Visa* pour permettre leur utilisation dans les commerces admissibles. C’est la solution la plus fiable, sécuritaire et écologique sur le marché.",
      answer_2:
        "Vous avez deux options. La plus simple est de demander au commerçant un paiement fractionné qui vous permettra de payer le montant restant avec une autre méthode de paiement. Dans lequel cas vous avez obtenu une carte Hello personnalisée à votre nom (suite à une vérification d'identité), vous avez la possibilité de charger votre compte Mon argent en suivant les instructions indiquées dans l’appli mobile Hello, et ces fonds permettront de compléter votre achat dans le commerce en une seule transaction.",
      answer_3:
        "En créant un compte, vous bénéficiez d'une expérience personnalisée. Vous pourrez facilement suivre vos achats, vos soldes et bénéficier d'une assistance rapide en cas de problème. Rejoignez notre communauté dès maintenant et profitez pleinement de tous les avantages d’une expérience connectée.",
      answer_4:
        'Bientôt! Malheureusement pour le moment la carte Hello ne peut pas être ajoutée à Apple Wallet ou Google Wallet pour payer avec votre téléphone mobile dans les commerces. Nous prévoyons avoir cette fonctionnalité bientôt. Un courriel sera envoyé à tous nos utilisateurs enregistrés pour annoncer cette nouvelle fonctionnalité lorsqu’elle sera disponible!',
      answer_5:
        'Consultez notre page <a style="color: var(--color-ultra-light);" href="{faqUrl}" target="_blank">Obtenir de l’aide</a> ou écrivez-nous à support@hellocard.ca si vous n’avez pas obtenu réponse à votre problème, nous allons rapidement entrer en communication avec vous.',
      faq: 'Pour toute autre question, consultez notre page ',
      faq_cta: 'Obtenir de l’aide',
      faq_cta_suffix: '.',
      question_1: 'Qu’est-ce qu’une carte-cadeau propulsée par Hello?',
      question_2: "Que dois-je faire si je veux acheter un article dont le prix est supérieur au montant que j'ai de disponible sur ma carte Hello?",
      question_3: 'Pourquoi dois-je créer un compte Hello pour faire un achat ou activer une carte-cadeau?',
      question_4: 'Est-ce que je peux utiliser Apple Wallet ou Google Wallet pour payer avec la carte Hello dans les commerces?',
      question_5: "Que dois-je faire si j'ai un problème avec l'achat, l'activation ou l'utilisation de la carte?",
      title: "Vous avez besoin d'aide?",
    },
    consumer_gift_card_info_modal: {
      action_title: 'Offrir en cadeau, comment ça fonctionne?',
      cancel_action: 'Fermer',
      modal_text_1: 'Choisissez le montant de la carte-cadeau.',
      modal_text_2: 'Ajoutez un message personnalisé (optionnel).',
      modal_text_3: 'Procédez au paiement.',
      modal_text_4: 'Téléchargez, imprimez ou envoyez la carte-cadeau par courriel. Votre bénéficiaire l’activera en ligne et recevra par la poste une carte Hello chargée avec le montant de votre carte-cadeau.',
      modal_title: 'Offrir en cadeau, comment ça fonctionne?',
      offer_consumer_gift_card_action: 'Offrir en cadeau',
    },
    consumer_gift_card_list: {
      subtitle: 'Des cartes-cadeaux qui offrent la liberté de choisir!',
      title: 'Idées cadeaux',
    },
    consumer_gift_card_marketing_card: {
      icon_1: 'mdi-store',
      icon_2: 'mdi-cursor-pointer',
      icon_3: 'mdi-diamond-stone',
      text_1: "Cette carte-cadeau est l’occasion parfaite d'encourager le commerce de proximité!",
      text_2: 'Donnez à vos proches la liberté de choisir parmi une variété de produits et de services offerts par tous nos commerçants.',
      text_3: 'En offrant cette carte-cadeau, vous soutenez non seulement l’économie locale, mais vous offrez également une expérience unique à chacun.',
      title_1: 'L’achat local',
      title_2: 'La liberté de choisir ',
      title_3: 'Une expérience unique',
    },
    consumer_gift_card_offer_small_card: {
      store_count: '{store_count} commerces admissibles',
    },
    consumer_gift_card_payment_form: {
      allow_communication: "Permettre à {partner} de m'envoyer des communications et promotions par courriel",
      credit_card_amount: 'Par carte de crédit',
      cta_pay: 'Payer',
      description: 'Vous pouvez utiliser jusqu’à {offer_total} de votre compte <i>Mon argent</i> pour effectuer votre paiement. Le solde restant, s’il y a lieu, sera à payer par carte de crédit.',
      detail_title: 'Détail du paiement',
      heading_credit_card: 'Carte de crédit',
      heading_my_cash: 'Compte <i>Mon argent</i><span>{my_cash_amount_max}</span>',
      hint: 'Min. {min_amount}$, max. {max_amount}$',
      my_cash_amount: 'Crédits <i>Mon argent</i>',
      open_purse_remaining: 'Il vous restera {open_purse_new_total} dans votre compte <i>Mon argent</i>.',
      pay_with_my_cash: 'Utiliser <i>Mon Argent</i>',
      payment_method_title: 'Effectuer le paiement',
      title: "Complétez l'achat de l'offre",
    },
    consumer_gift_card_payment_success: {
      cta_cancel: 'Fermer la fenêtre et poursuivre mes achats',
      cta_faq: 'Vous avez des questions? Consultez la FAQ.',
      cta_merchants: 'Voir la liste des commerces',
      cta_my_account: 'Vos commandes et vos cartes-cadeaux sont accessibles dans la section ',
      cta_my_account_2: 'Aller à&nbsp;<i>Mon compte</i>&nbsp;pour consulter ma commande',
      cta_my_account_italic: 'Mon compte.',
      cta_my_account_pending_order: 'Votre commande de carte-cadeau est en cours de validation et sera disponible dans la section ',
      download_consumer_gift_card: 'Télécharger la carte-cadeau',
      download_receipt: 'Consulter votre reçu',
      order_id: 'Numéro de commande:',
      redirect_consumer_gifts: 'Aller à mes cartes-cadeaux',
      subtitle: 'Un reçu a été envoyé à votre adresse courriel.',
      title: 'Votre paiement a été effectué avec succès!',
    },
    consumer_gift_card_quote_card: {
      description_1: ' bénéficiaire d’une carte-cadeau',
      description_2: " acheteur d'une carte-cadeau",
      name_1: 'Jean Maréchal',
      name_2: 'Caroline Bui',
      quote_1: "J'ai adoré recevoir cette carte-cadeau qui fonctionne sur le réseau Visa, c'est simple à utiliser en magasin puisqu'on paye comme avec une carte de crédit!",
      quote_2: "L’achat en ligne a été super simple et rapide. J'ai beaucoup aimé la flexibilité de pouvoir remettre la carte-cadeau en personne ou électroniquement.",
    },
    consumer_gift_card_send_by_email_modal: {
      action_title: 'Envoyer par courriel',
      cancel_action: 'Annuler',
      label_address: 'Adresse courriel du bénéficiaire',
      label_name: 'Nom du bénéficiaire',
      label_sender_name: 'Votre nom',
      language_radios: {
        english: {
          label: 'Anglais',
        },
        french: {
          label: 'Français',
        },
        title: 'Langue de préférence du bénéficiaire:',
      },
      modal_text_1: "Nous recommandons de vérifier avec le bénéficiaire que le courriel de la carte-cadeau n'est pas dans son répertoire d'indésirables.",
      modal_title: 'Envoyer par courriel',
      send_action: 'Envoyer',
      sent_to_message: 'La carte-cadeau a été envoyée avec succès à:',
      sent_to_message_error: "Le courriel n'a pas pu être envoyé.",
    },
    consumer_gift_card_store_marketing: {
      see_more_cta: 'Voir les {closed_loop_size} commerces admissibles',
      text: "Offrez l’accès à {closed_loop_size} commerces sur une seule carte-cadeau et laissez à vos proches le plaisir de choisir où l'utiliser.",
      title_2_9: 'Une seule carte, {closed_loop_size} possibilités',
      title_9_more: 'Une seule carte, une myriade de possibilités',
    },
    consumer_gift_card_swap_how_it_works: {
      activate_cta: 'Activer ma carte-cadeau',
      activate_instruction_1_1: 'Vous avez reçu une carte-cadeau avec un URL d’activation et un code d’activation?',
      activate_instruction_1_2: 'Cliquez ici',
      activate_instruction_1_3: 'pour activer votre carte-cadeau.',
      activate_instruction_2: 'Saisissez le code d’activation à 8 chiffres pour déposer le montant reçu en cadeau sur votre carte Hello.',
      activate_instruction_3: 'Votre carte Hello sera instantanément rechargée. Si vous n’avez pas déjà une carte Hello, vous la recevrez dans un délai de 7 à 10 jours ouvrables.',
      activate_swap_cta: 'Acheter maintenant',
      activate_tab_title: 'Activer une carte-cadeau',
      activate_tab_title_mobile: 'Activer',
      buy_cta: 'Acheter maintenant',
      buy_instruction_1: 'Choisissez le montant à offrir en cadeau, ajoutez un message personnalisé et procédez au paiement.',
      buy_instruction_2: 'Vous recevrez une carte-cadeau en format PDF que vous pourrez imprimer pour remettre en main propre ou envoyer par courriel.',
      buy_instruction_3: 'L’heureux élu activera sa carte-cadeau en ligne et recevra une carte physique Hello par la poste. S’il a déjà une carte Hello, sa carte Hello sera rechargée instantanément.',
      buy_swap_cta: 'Activer ma carte-cadeau',
      buy_tab_title: 'Acheter pour offrir en cadeau',
      buy_tab_title_mobile: 'Acheter',
      title: 'C’est simple, flexible et rapide!',
    },
    consumer_gift_card_terms_and_conditions_modal: {
      action_title: 'Ok',
      consent_text: 'En procédant au paiement, vous consentez aux',
      consent_text_link: 'Termes et conditions de vente.',
      modal_sub_title: "En procédant à l’achat et au paiement d'une carte-cadeau, vous consentez aux termes et conditions de vente indiqués ci-dessous:",
      modal_text1: 'La carte-cadeau n’est pas remboursable.',
      modal_text10:
        "Réseau Hello n’est pas responsable des fonds achetés autre que d’en donner l’accès par l’utilisation de la plateforme Hello. Si le partenaire affilié faisant la promotion du programme cesse d'exercer ses activités ou d'honorer vos fonds, votre seul recours en matière de remboursement de la valeur payée est directement contre ce partenaire affilié ou son représentant en insolvabilité.",
      modal_text2: 'La carte-cadeau doit être activée en ligne avec son code d’activation sur la page web dédiée à l’activation.',
      modal_text3: 'La carte-cadeau sera déposée sur une carte Hello sous forme de solde et ne peut être échangée contre de l’argent en espèces.',
      modal_text4: 'Si le bénéficiaire de la carte-cadeau n’a pas de carte Hello, une nouvelle carte lui sera acheminée par la poste dans les 7 à 10 jours ouvrables suivant l’activation et la demande de carte.',
      modal_text5: 'Le solde peut être consulté en tout temps par le bénéficiaire de la carte-cadeau sur espacehello.ca ou dans l’appli mobile Hello.',
      modal_text6: 'Le solde obtenu peut être utilisé seulement dans les commerces admissibles au programme de la carte-cadeau.',
      modal_text7: 'La liste des commerces admissibles n’est pas finale et peut varier occasionnellement.',
      modal_text8: "Après {dormancyMonths} mois d'inactivité, des frais d'inactivité de {dormancyFixedFee} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
      modal_text8_with_extension:
        "Après {dormancyMonths} mois d'inactivité, des frais d'inactivité de {dormancyFixedFee} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé, sauf si vous avez fait une demande à support@hellocard.ca avant la fin du {dormancyMonths}e mois, auquel cas aucun frais d'inactivité ne sera appliqué jusqu'au {dormancyExtendedMonths}e mois suivant la date d'achat.",
      modal_text9: 'Un frais de remplacement de carte Hello, jusqu’à 10$ plus taxes, pourra être exigé en cas de perte, vol ou bris.',
      modal_title: 'Termes et conditions de vente',
    },
    consumer_gift_card_totals: {
      subtext: 'Suite à votre achat, et dans la section <i>Mon compte</i>, vous aurez accès aux options vous permettant de distribuer, physiquement ou électroniquement, la carte-cadeau au bénéficiaire.',
      title: 'Détails du paiement',
      total: 'À payer',
    },
    corpo_company_manager: {
      proceed_text: 'Passer à la caisse',
      title: 'Votre entreprise',
    },
    corpo_company_modal: {
      add: 'Créer une nouvelle entreprise',
      cancel: 'Annuler',
      edit: 'Modifier les informations existantes',
      save: 'Enregistrer',
    },
    corpo_company_step: {
      city_label: 'Ville',
      company_address_label: "Adresse de l'entreprise",
      company_name_label: "Nom de l'entreprise",
      contact: 'Contact',
      contact_label: 'Contact',
      email_label: 'Adresse courriel',
      name_label: 'Nom complet',
      number_of_employees: {
        choices: {
          '1_50': '1-50',
          '201_500': '201-500',
          '500_MORE': '500+',
          '51_200': '51-200',
        },
        label: "Nombre d'employés",
      },
      phone_number_extension_label: 'Poste',
      phone_number_label: 'Numéro de téléphone',
      postal_code_label: 'Code postal',
      proceed_text: 'Passer à la caisse',
      province_label: 'Province',
      title_company: 'Informations concernant votre entreprise',
      title_contact: 'Coordonnées de la personne contact',
    },
    corpo_confirmation: {
      available_in_account: 'Vous avez accès à vos cartes-cadeaux et les options de distribution en tout temps dans votre <i>Compte entreprise</i>.',
      batch_id: 'Identifiant de lot:',
      download_invoice: 'Télécharger mon reçu',
      download_vouchers: 'Distribuer vos cartes-cadeaux',
      go_to_account: 'Voir ma commande dans mon <i>Compte entreprise</i>',
      order_again: 'Passer une autre commande',
      order_details: 'Détails de la commande:',
      order_id: 'Numéro de commande:',
      order_total: 'Total de la commande:',
      print_cta: 'Fichier PDF multi-page',
      print_label: 'Idéal pour imprimer:',
      question_text: 'Vous avez des questions? Veuillez nous envoyer un courriel à <a href="mailto:support@hellocard.com">support@hellocard.com</a>',
      redirect_corporate_gifts: 'Aller à mes cartes-cadeaux',
      share_email_cta: 'Fichier Zip (un fichier PDF par carte-cadeau)',
      share_email_label: 'Idéal pour partager par courriel:',
      share_whatever_cta_excel: 'Fichier Excel',
      share_whatever_cta_pdf: 'Fichier PDF informatif',
      share_whatever_label: 'Idéal pour partager à votre guise et/ou pour vos obligations fiscales de documentation:',
      subtitle: 'Un courriel de confirmation vous a été envoyé. Votre numéro de commande est <b>{orderId}</b>.',
      title: 'Votre commande de cartes-cadeaux a été passée avec succès!',
    },
    corpo_email_verification_required: {
      manual_verify: 'Vérifier le statut du courriel',
      order_voucher: 'Commander des cartes-cadeaux',
      send_link: 'Envoyer un nouveau lien de vérification',
      text: 'Veuillez cliquer sur le lien qui vous a été envoyé afin de vérifier votre adresse courriel.',
      title_pending: 'Nous avons envoyé un lien de vérification à votre adresse courriel.',
      title_success: 'Votre adresse courriel a été vérifiée avec succès!',
    },
    corpo_explanation: {
      action: 'Acheter maintenant',
      faqUrl: 'Vous avez des questions? Consultez la <a target="_blank" href="{faqUrl}">FAQ</a>.',
      step_1: 'Procédez à l’achat en ligne.',
      step_2: 'Obtenez des cartes-cadeaux en format PDF avec lien URL et code d’activation.',
      step_3: 'Distribuez les cartes-cadeaux à vos employés avec la méthode de votre choix : physique (impression) ou digitale (envoi par courriel).',
      step_4: 'Vos employés activeront leur carte-cadeau et recevront une carte rechargeable Hello par la poste (ou leur carte Hello sera rechargée s’ils en ont déjà une).',
      title: 'C’est simple et flexible avec la solution Hello',
    },
    corpo_inform_first_account_business_modal: {
      confirm_text: 'OK, procéder au paiement',
      text: 'Vous avez maintenant accès à un nouveau lien COMPTE ENTREPRISE dans la barre noire Hello en haut de l’écran afin de vous permettre d’accéder et de distribuer vos cartes-cadeaux en tout temps.',
      title: 'Votre première entreprise a été créée avec succès!',
    },
    corpo_intro: {
      img_alt: 'Image promotionnelle',
      text: 'Réservé exclusivement aux entreprises. Si votre commande excède 10,000$, veuillez nous contacter à <a href="mailto:sales@hellonetwork.ca">sales@hellonetwork.ca</a>.',
      title: 'Programme de reconnaissance employé pour entreprises',
    },
    corpo_offer_terms_and_conditions_modal: {
      action_title: 'Ok',
      activation: 'Les cartes-cadeaux doivent être activées en ligne avec leur code d’activation sur la page web dédiée à l’activation.',
      consent_text: 'En procédant à l’achat et au paiement de la commande, vous consentez aux',
      consent_text_link: 'Termes et conditions de vente.',
      expiration: {
        bonus_fixed: 'Les cartes-cadeaux expirent le {bonus_end_date}.',
        bonus_relative: "Les cartes-cadeaux expirent {bonus_duration_days} jours après la date d'achat.",
        payment_dormancy: "Après {dormancy_activity_trigger} mois d'inactivité, des frais d'inactivité de {dormancy_fee}$ par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
        payment_dormancy_with_extension:
          "Les cartes-cadeaux sont assujetties à des frais d’inactivité. Après {dormancy_activity_trigger} mois d'inactivité, des frais d'inactivité de {dormancy_fee}$ par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
      },
      modal_sub_title: 'En procédant à l’achat et au paiement de la carte-cadeau, vous consentez aux termes et conditions de vente indiqués ci-dessous:',
      modal_title: 'Termes et conditions de vente',
      non_refundable: 'Les cartes-cadeaux ne sont pas remboursables.',
      redeem: 'Le seul moyen d’utiliser une carte-cadeau en magasin est avec une carte Hello.',
      reserved: 'Le programme est réservé aux entreprises.',
      rights: 'Hello Network se réserve le droit, à son entière discrétion, d’annuler des commandes de cartes-cadeaux.',
      taxes: 'Des taxes s’appliquent seulement si des frais sont appliqués sur les cartes-cadeaux, et sont applicables seulement sur ces frais.',
      untradeable: 'Les cartes-cadeaux seront déposées sur une carte Hello et ne peuvent être échangées contre de l’argent en espèces.',
    },
    corpo_order_error_modal: {
      ok: 'Ok',
      text: 'Veuillez nous contacter à <a href="mailto:sales@hellonetwork.ca">sales@hellonetwork.ca</a> pour une commande excédant 10,000$. Vous avez des questions? Consultez la <a href="{faqUrl}" target="_blank">FAQ</a>.',
      title: 'Le montant que vous avez soumis excède 10,000$.',
    },
    corpo_order_process: {
      step_company_title: 'Renseignements sur votre entreprise',
      step_order_title: 'Détails de votre commande',
      step_payment_title: 'Paiement',
    },
    corpo_order_step: {
      amount_hint: 'Min. {min_amount}$, max. 500$',
      amount_label: 'Montant',
      msg_hint: 'Max 150 caractères',
      msg_label: 'Message personnalisé (optionnel)',
      msg_placeholder: 'Ex: Un gros merci à tous nos employés! Gâtez-vous! - La direction',
      proceed_text: 'Suivant',
      quantity_hint: 'Min. 1',
      quantity_label: 'Quantité',
      subtotal_label: 'Sous-total',
      title: 'Information concernant votre commande',
    },
    corpo_payment_step: {
      card_name_label: 'Nom sur la carte',
      card_name_placeholder: 'Prénom Nom',
      card_number_label: 'Numéro de la carte',
      card_number_placeholder: '1234 1234 1234 1234',
      cvc_label: 'CVC',
      cvc_placeholder: '000',
      expiration_date_label: "Date d'expiration",
      expiration_date_placeholder: 'MM / AA',
      explanation: 'Suite à votre achat, dans la section <i>Compte entreprise</i>, vous aurez accès aux options vous permettant de télécharger vos cartes-cadeaux.',
      fees_details: '$ + % par carte-cadeau',
      fees_title: 'Frais',
      form_title: 'Effectuer le paiement',
      payment_details: 'Détails du paiement',
      proceed_text: 'Payer',
      taxes_details: 'TPS et TVQ',
      taxes_title: 'Taxes (applicables sur les frais)',
      total: 'À payer',
      vouchers_title: 'Cartes-cadeaux',
    },
    corpo_payment_summary: {
      payment_items: {
        fees_title: 'Frais',
        fees_title_with_minimum_fee: 'Frais (min. {minimum_fee} par carte-cadeau)',
        tax_description: 'TPS + TVQ',
        tax_title: 'Taxes (applicable sur les frais)',
        voucher_title: 'Cartes-cadeaux',
      },
      subtext: 'Suite à votre achat, dans la section <i>Compte entreprise</i>, vous aurez accès aux options vous permettant de télécharger vos cartes-cadeaux.',
      title: 'Détail du paiement',
      total: 'Total',
    },
    corpo_retailers_modal: {
      activator: 'Voir la liste des commerces admissibles',
      close: 'Fermer',
      title: 'Liste des commerces admissibles',
    },
    corpo_verifying_email: {
      cancel: 'Annuler',
      error_goback: 'Retourner à la page de l’offre',
      error_send_link: 'Envoyer un nouveau lien',
      error_text: 'Veuillez vous assurer que le lien utilisé est correct ou cliquez sur le lien ci-dessous pour envoyer un nouveau lien de vérification.',
      error_text_no_auth: 'Veuillez vous assurer que le lien utilisé est correct ou connectez-vous pour envoyer un nouveau lien de vérification.',
      error_title: "Une erreur s'est produite lors de la vérification de votre courriel",
      order_vouchers: 'Commander des cartes-cadeaux',
      success_button: 'OK',
      success_text: 'Vous pouvez maintenant procéder à des achats pour entreprises.',
      success_title: 'Votre adresse courriel a été vérifiée avec succès!',
      working_title: 'Veuillez patienter pendant que nous vérifions votre adresse courriel.',
    },
    corpo_view: {
      coming_soon_offer_message: "L'offre sera disponible le {date}",
      coming_soon_offer_message_no_date: "L'offre sera disponible bientôt",
      not_purchasable_offer_message: "Désolé, le programme n'est plus disponible.",
    },
    delete_a_card_action: {
      modal: {
        actions: {
          cancel: 'Annuler',
          confirm: 'Confirmer',
        },
        description: 'La carte suivante sera enlevée de la liste:',
        title: 'Supprimer une carte de crédit',
      },
      title: 'Supprimer la carte',
    },
    destination_modal: {
      see_store: 'Voir les commerces admissibles',
    },
    discover_space: {
      title: 'Découvrez les espaces Hello',
    },
    empty_offers_list: {
      header: 'Désolé',
      message: 'Aucune offre disponible pour le moment.',
    },
    empty_offers_list_with_category: {
      header: 'Désolé',
      message: 'Aucune offre disponible dans cette catégorie pour le moment.',
    },
    find_card_step_form: {
      error_modal: {
        already_activated: {
          text: 'Allez à la section Mon compte pour consulter vos soldes.',
          title: 'Vous avez déjà activé cette carte.',
        },
        btnText: 'Ok',
        cannot_be_activated: {
          text: "Si vous avez besoin d'aide, n'hésitez pas à contacter notre service à la clientèle à support@hellocard.ca en utilisant votre identifiant de carte à 9 chiffres imprimés à l'endos de votre carte.",
          title: 'Cette carte ne peut pas être activée.',
        },
        instant: {
          card: {
            activation: {
              not: {
                enabled: {
                  text: 'Désolé, cette carte n’a pas été pré-activée.',
                  title: 'Cette carte ne peut pas être activée.',
                },
              },
            },
            has: {
              no: {
                voucher: {
                  text: 'Désolé, cette carte n’a pas été pré-activée.',
                  title: 'Cette carte ne peut pas être activée.',
                },
              },
            },
            status: {
              not: {
                pending: {
                  text: 'Allez à la section Mon compte pour consulter vos soldes.',
                  title: 'Vous avez déjà activé cette carte.',
                },
              },
            },
          },
        },
        internal: {
          server: {
            error: {
              text: 'Erreur serveur interne.',
              title: 'Erreur serveur interne',
            },
          },
        },
        invalid: {
          offer: {
            status: {
              text: "L'offre liée à la carte est terminée.",
              title: 'Désolé, la carte n’a pas pu être activé.',
            },
          },
        },
        invalid_instant_card_information: {
          link: 'Mon compte',
          text_1: 'Si vous avez reçu votre carte par la poste, allez à ',
          text_2: "pour activer votre carte avec le code d'activation.",
          text_3: 'Sinon, veuillez réessayer ou contacter notre service client à support@hellocard.ca.',
          title: 'Les informations de carte que vous avez saisi ne sont pas valides.',
        },
        no: {
          available: {
            offer: {
              text: "Désolé, cette carte ne peut pas être activée. Veuillez contacter notre service à la clientèle à support@hellocard.ca pour obtenir de l'assistance.",
              title: 'Cette carte ne peut pas être activée.',
            },
          },
        },
        reached: {
          instant: {
            card: {
              activation: {
                limit: {
                  day: {
                    text: "Pour des raisons de sécurité, nous ne permettons pas d'activer au-delà de 15 cartes par jour. Veuillez réessayer demain.",
                    title: 'Désolé, la carte n’a pas pu être activé.',
                  },
                },
              },
            },
          },
        },
        system: {
          bad: {
            gateway: {
              text: 'Erreur serveur mauvaise passerelle (Bad Gateway).',
              title: 'Erreur serveur',
            },
          },
        },
      },
      found_card_modal: {
        balance: 'Votre solde',
        dormancy: "Après {delay} mois d'inactivité, des frais d'inactivité de {amount}$ par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
        expiration_bonus: 'Le solde expirera le {bonus_expiration_date}.',
        expiration_contribution: 'La partie inutilisée du solde au {closure_date} sera transférée à votre compte Mon argent à cette date.',
        expiration_mixed: 'La bonification de {bonus_amount} expirera le {bonus_expiration_date} et la partie inutilisée de la contribution de {payment_amount} au {closure_date} sera transférée à votre compte Mon argent à cette date.',
        less_detail: 'Cacher les détails',
        more_detail: 'Voir les détails',
        ok: 'Ok',
        reassuring_text: "Ne vous inquiétez pas, vos soldes sont accessibles dans la section Mon compte et dans l'application mobile Hello.",
        text: 'Elle est préchargée avec la carte-cadeau suivante :',
        title: 'Nous avons identifié votre carte!',
      },
      found_card_modal_without_voucher: {
        no_offer_info_text: 'Elle est actuellement vide. Après son activation, vous pourrez la charger et la recharger avec des offres promotionnelles.',
        title: 'Nous avons identifié votre carte!',
      },
      found_multi_offer_instant_card_modal: {
        ok: 'OK',
        text: 'Elle est préchargée avec les {number_of_offers} soldes suivants pour un total de {total_amount}:',
        title: 'Nous avons trouvé votre carte!',
      },
      found_multipublisher_offer_card_modal: {
        multipublisher_selection: {
          header_title: "Vous devez sélectionner l'une des options suivantes:",
          participating_merchant: 'Voir la liste des commerces',
          sold_out: 'Épuisée',
          store_list_close_modal_title: 'Fermer',
          store_list_link_title: 'Voir la liste des commerces',
          store_list_modal_title: 'Liste des commerces',
        },
        text: "Votre carte est préchargée avec l'offre suivante :",
      },
      info_modal_last_4_digits: {
        image_alt_text: 'Exemple du dos de la carte avec les 4 derniers chiffres de la carte mis en évidence',
        text: 'Les 4 derniers chiffres de votre numéro de carte.',
        title: 'Numéro de carte',
      },
      info_modal_ref_id: {
        image_alt_text: "Exemple du dos de la carte avec l'identifiant de carte mis en évidence",
        text: "9 chiffres imprimés à l'endos de la carte.",
        title: 'Identifiant de carte',
      },
      last_4_digits_hint: '**** **** **** XXXX',
      last_4_digits_label: '4 derniers chiffres du numéro de carte',
      next: 'Suivant',
      ref_id_hint: '9 chiffres au dos de la carte',
      ref_id_label: 'Identifiant de carte',
    },
    forgot_password_modal: {
      action: 'Envoyer',
      cancel: 'Annuler',
      description: 'Saisissez et vérifiez votre adresse courriel, nous allons vous envoyer un courriel de réinitialisation de mot de passe.',
      email_field: 'Adresse courriel',
      title: 'Aucun problème!',
    },
    gift_certificate_card: {
      action: 'Activer ma carte-cadeau',
      status_label_closed: 'Terminé',
      status_label_coming_soon: 'Bientôt disponible',
      status_label_deactivated: 'Indisponible',
      status_label_sold_out: 'Épuisé',
    },
    gift_certificate_card_big: {
      action: 'Activer ma carte-cadeau',
      status_label_closed: 'Terminée',
      status_label_coming_soon: 'Bientôt disponible',
      status_label_deactivated: 'Indisponible',
      status_label_sold_out: 'Épuisée',
    },
    gift_certificate_offer_multiple_publisher_big_card: {
      call_to_action: 'Activer ma carte-cadeau',
      expiration: "L'offre expire le {date}",
    },
    gift_certificate_offer_publisher_big_card: {
      call_to_action: 'Activer ma carte-cadeau',
      progress_left: 'Disponible',
    },
    gift_certificate_offer_publisher_small_card: {
      progress_left: 'Disponible',
    },
    gift_certificate_offer_single_publisher_big_card: {
      call_to_action: 'Activer ma carte-cadeau',
      call_to_action_consumer_gift_card: 'Offrir en cadeau',
      expiration: "L'offre expire le {date}",
    },
    h_chip_big: {
      obtain: 'Obtenez',
      pay: 'Payez',
    },
    h_chip_small: {
      obtain: 'Obtenez',
      pay: 'Payez',
    },
    h_dialog: {
      close: 'Fermer',
    },
    h_progress_bar: {
      closed: "L'offre est terminée",
      coming_soon: "L'offre sera disponible le {date}",
      coming_soon_no_date: "L'offre sera disponible bientôt",
      condition: 'Minimum {minPurchase} par achat, maximum {maxPurchase} par utilisateur',
      expiration: "L'offre expire le {date}",
      progress_coming_soon: 'Reste {totalNumber}',
      progress_left: 'Reste {currentNumber} / {totalNumber}',
      sold_out: "L'offre est épuisée",
    },
    home_offers_by_status: {
      available: 'Promotions disponibles',
      coming_soon: 'Promotions à venir',
      list_all: {
        available: 'Afficher toutes les promotions disponibles ({numItems})',
        coming_soon: 'Afficher toutes les promotions à venir ({numItems})',
        missed: 'Afficher toutes les promotions manquées ({numItems})',
      },
      return: 'Retour',
      show_more: 'Afficher Plus',
      statuses: {
        available: 'Promotions disponibles',
        coming_soon: 'Promotions à venir',
        missed: 'Promotions manquées',
      },
    },
    home_offers_list: {
      escapade: "Envie d'une escapade hors {region}?",
      list_all: 'Voir toutes les promotions',
      subtitle: "Augmentez votre pouvoir d'achat",
      title: 'En promotion',
    },
    home_offers_regions: {
      escapade: "Envie d'une escapade hors {region}?",
      list_all: {
        ab: 'Afficher toutes les promotions en Alberta ({numItems})',
        nb: 'Afficher toutes les promotions au Nouveau-Brunswick ({numItems})',
        on: 'Afficher toutes les promotions en Ontario ({numItems})',
        qc: 'Afficher toutes les promotions au Québec ({numItems})',
      },
    },
    home_page_offer_bonus_small_card: {
      space: 'Une offre',
    },
    home_search_bar: {
      category: {
        all: 'Toutes les catégories',
        fashion_style: 'Mode et style',
        food_groceries: 'Alimentation et épiceries',
        home_cars: "Pour la maison et l'auto",
        leisure_getaways: 'Loisirs et escapades',
        miscellaneous: 'Services et divers',
        mixed: 'Destination de magasinage',
        restaurants_bars: 'Restaurants et bars',
      },
    },
    home_status_view: {
      return: 'retour',
      show_more: 'Voir plus',
      statuses: {
        available: 'Promotions disponibles',
        coming_soon: 'Promotions à venir',
        missed: 'Promotions manquées',
      },
    },
    instant_card_activation_view: {
      back: {
        offer: "Retour à l'activation de l'offre",
        space: "Retour à l'espace",
      },
      login_step: {
        disclaimer: 'La carte prépayée Visa Hello Instantanée est émise par Peoples Trust Company conformément à la licence de Visa Int. *Marque de commerce de Visa International Service Association et utilisée sous licence par Peoples Trust Company.',
      },
      step_1: {
        instruction: 'Trouvons votre carte!',
        title: 'Identifiez votre carte',
      },
      step_2: {
        fact_1: "La carte n'est ni retournable ni remboursable.",
        fact_2: 'La carte est rechargeable.',
        fact_3: 'Seul Hello Network peut charger des fonds sur la carte.',
        fact_4: 'La carte peut être acceptée seulement aux commerces admissibles.',
        fact_5: 'Vous ne pouvez pas effectuer de retraits en espèces avec la carte.',
        fact_6: "Les fonds chargés sur la carte ne sont pas assurés par la Société d'assurance-dépôts du Canada (SADC).",
        instruction: "Avant d'activer votre carte, vous devez savoir...",
        next: 'Suivant',
        title: 'Ce que vous devez savoir',
      },
      step_3: {
        info: 'Nous avons besoin de votre information pour…',
        info_fact_1: 'protéger votre solde en cas de perte ou vol de votre carte',
        info_fact_2: "permettre à notre service à la clientèle de vous identifier si vous avez besoin d'aide",
        instruction: 'Veuillez compléter le formulaire ci-dessous.',
        title: 'Activez votre carte',
      },
      step_4: {
        balance: 'Solde',
        instruction: 'Découvrez les commerces admissibles ci-dessous',
        next: 'Ok',
      },
      step_chose_an_option: {
        title: 'Choisissez une option',
      },
      subtitle: {
        0: 'Un compte Hello est requis pour activer votre carte.',
        1: 'Vous êtes authentifié!',
        3: 'Une dernière étape avant de pouvoir utiliser votre carte!',
        4: 'Nous vous avons envoyé un courriel avec un lien direct vers la liste des commerces admissibles. Vous avez accès à cette liste ainsi qu’à vos soldes en tout temps dans Mon compte.',
        '1-2': 'Veuillez suivre les étapes ci-dessous afin d’activer votre carte.',
      },
      title: 'Activez votre carte prépayée Visa* Hello Instantanée',
    },
    instant_card_all_set_modal: {
      all_done: "J'ai terminé",
      available_apple: "Disponible sur l'App Store",
      available_google: 'Disponible sur Google Play',
      btn_text: 'Envoyer',
      download_incentive1: "Obtenir votre NIP en cas d'oubli",
      download_incentive2: 'Consulter vos soldes à tout moment',
      download_incentive3: 'Et beaucoup plus!',
      download_other: 'Ou recherchez "hellocard" dans les magasins d\'applications mobiles.',
      download_title: "Téléchargez l'application mobile Hello pour :",
      input_hint: '+1 (XXX) XXX-XXXX',
      input_label: 'Numéro de téléphone mobile',
      more_offers: "Découvrir plus d'offres",
      qr_code: 'Code QR',
      reloadable: "Votre carte est rechargeable. Maximisez la valeur de votre carte en y ajoutant d'autres offres!",
      scan_qr: "Scannez ce code QR avec votre téléphone pour télécharger l'application.",
      sms_link: "Ou recevez par SMS le lien pour télécharger l'application.",
      title: 'Vous êtes prêt!',
    },
    instant_card_apply: {
      action: 'Soumettre',
      cancel_action: 'Annuler',
      description: 'Veuillez remplir le formulaire ci-dessous. Nous avons besoin de vos informations pour:',
      hint_city: 'Maximum 50 caractères',
      hint_first_name: 'Maximum 20 caractères',
      hint_last_name: 'Maximum 20 caractères',
      hint_mobile_number: '+1 (123) 123-1234',
      hint_postal_code: 'X9X 9X9',
      hint_street: 'Maximum 30 caractères',
      label_apartment_number: 'Appt.',
      label_city: 'Ville*',
      label_civic_number: 'N° civique*',
      label_consent: 'Je consens aux termes de <a href="{url1}" target="_blank">l’Entente du titulaire de carte</a> et à <a href="{url2}" target="_blank">l’Entente des communications électroniques</a>.',
      label_date_of_birth: 'Date de naissance*',
      label_first_name: 'Prénom*',
      label_gender: 'Genre*',
      label_last_name: 'Nom de famille*',
      label_mobile_number: 'N° de tél. cellulaire',
      label_postal_code: 'Code postal*',
      label_province: 'Province*',
      label_street: 'Nom de rue*',
      point1: 'livrer la carte à votre domicile (7 à 10 jours ouvrables)',
      point2: 'protéger vos soldes en cas de perte de carte',
      point3: 'permettre à notre service à la clientèle de vous identifier',
      title: 'Ma carte Hello Instantanée',
    },
    instant_card_listing_header: {
      card_label: 'Carte Hello Instantanée',
      status: 'Statut',
    },
    instant_card_summary: {
      submit: 'Choisir',
      title: 'Hello Instantanée',
    },
    manage_subscription_modal: {
      action: "ALLEZ À L'INTERFACE DE GESTION",
      info_1: "Ne plus avoir accès aux offres de type bonification de l'espace Hello - {space}",
      info_2: "Perdre toute bonification obtenue dans l'espace Hello - {space}",
      text: "Prenez note que si vous vous désabonnez dans l'interface de gestion, vous consentez à :",
      title: 'Gérer mon abonnement',
    },
    navbar: {
      about: 'À propos de Hello',
      business_console: 'Compte Entreprise',
      close: 'Fermer',
      faq: "Obtenir de l'aide",
      language: {
        en: 'en',
        fr: 'fr',
      },
      logo_alt_text: "Hello page d'accueil",
      logout: 'Se déconnecter',
      my_account: 'Mon compte',
      nav_alt_text: 'Menu de navigation',
      signin: 'Se connecter',
    },
    offer_card: {
      obtain: 'Obtenez',
      pay: 'Payez',
    },
    offer_card_big: {
      back_action: "Retour à l'espace",
      back_action_publisher: "Retour à l'offre",
      back_other: 'Retour',
      buy_action: "J'achète",
    },
    offer_card_error_modal: {
      default_text: "Désolé! Une erreur s'est produite, veuillez actualiser la page ({error_code})",
      ok: 'Ok',
      title: 'Une erreur est survenue.',
    },
    offer_publishers_display: {
      sub_title: "Aucune option n'a encore été ajoutée à cette offre.",
      title: 'À venir',
    },
    offer_small_card: {
      activate_voucher: 'Activer ma carte-cadeau',
      buy_as_gift_button: 'Offrir en cadeau',
      buy_now_button: 'Acheter Maintenant',
      see_offer_button: "Voir l'offre",
      see_offer_link: "Voir l'offre",
      see_stores_link: 'Voir détails et commerces admissibles',
    },
    offer_stores_display: {
      address: '{number_stores} addresse | {number_stores} addresse | {number_stores} addresses',
      destination: '{number_stores} destination | {number_stores} destination | {number_stores} destinations',
      franchise_website: 'Visiter le site web',
      hide_address_list: 'Cacher {number_stores} adresse | Cacher {number_stores} adresse | Cacher les {number_stores} adresses',
      hide_list: 'Cacher {number_stores} destination | Cacher {number_stores} destination | Cacher les {number_stores} destinations',
      show_address_list: 'Afficher {number_stores} adresse | Afficher {number_stores} adresse | Afficher les {number_stores} adresses',
      show_list: 'Afficher {number_stores} destination | Afficher {number_stores} destination | Afficher les {number_stores} destinations',
      sub_title: "Aucun commerce n'a encore été ajouté à cette offre.",
      title: 'À venir',
    },
    open_wallet_hint_modal: {
      title: 'Portefeuille ouvert',
    },
    overlay_app: {
      extra: 'Ou recherchez “hellocard” dans les Stores',
      message: "Avez-vous téléchargé l'appli Hello? Téléchargez-la maintenant pour avoir accès à vos soldes et recevoir des alertes lorsque vous faites des transactions avec la carte.",
      title: 'Appli Hello',
    },
    overlay_renew_card: {
      action_apply: 'Obtenir une nouvelle carte',
      action_renew: 'Renouveler ma carte',
      deactivated: {
        text: 'Vous devez renouveler votre carte Hello afin de pouvoir utiliser vos soldes dans les commerces.',
        title: 'Votre carte Hello est désactivée',
      },
      expire_soon: {
        text: 'Vous devrez alors obtenir une nouvelle carte Hello afin de pouvoir continuer à dépenser vos soldes dans les commerces.',
        title: 'Votre carte Hello sera expirée le {date}',
      },
      expired: {
        text: 'Vous devez renouveler votre carte Hello afin de pouvoir utiliser vos soldes dans les commerces.',
        title: 'Votre carte Hello est expirée',
      },
      lost_stolen: {
        text: 'Vous devez renouveler votre carte Hello afin de pouvoir utiliser vos soldes dans les commerces.',
        title: 'Votre carte Hello est déclarée perdue/volée',
      },
      success: {
        text: 'Vous recevrez une nouvelle carte Hello par la poste dans un délai de 7 à 10 jours ouvrables.',
        title: 'Votre carte a été renouvelée avec succès!',
      },
    },
    overlay_request_card: {
      action: 'Obtenir ma carte',
      action_error_option: 'Mon compte',
      active_card_error_message: 'Vous avez déjà procédé à la demande de votre carte Hello et vous ne pouvez pas obtenir deux fois cette carte. Fermer la fenêtre et rafraîchissez la page avec votre fureteur.',
      active_card_error_title: 'Oups!',
      apply_url_error_modal_message: 'Veuillez contacter le service à la clientèle de Hello à support@hellocard.ca.',
      apply_url_error_modal_title: 'Une erreur inconnue est survenue',
      description:
        '<b>Vous devez procéder à la demande de votre carte Hello Visa.</b> Tous vos soldes y seront transférés automatiquement et vous pourrez les utiliser en magasin avec la carte comme vous le faites normalement avec une carte de crédit ou de débit.',
      pending_application_error_message: 'Vous avez déjà procédé à la demande de votre carte Hello et vous ne pouvez pas obtenir deux fois cette carte. Fermer la fenêtre et rafraîchissez la page avec votre fureteur.',
      pending_application_error_title: 'Oups!',
      required_verify_email_modal_message: 'Vous devez avoir vérifié votre adresse courriel pour procéder à la demande de votre carte Hello.',
      required_verify_email_modal_title: 'Vérifiez votre adresse courriel',
      title: 'Votre carte Hello',
    },
    overlay_request_card_reminder_modal: {
      cta_get_card: 'Obtenir ma carte',
      cta_not_now: 'Plus tard',
      message:
        "Vous n'avez pas procédé à la demande de votre carte Hello (bouton <i>Obtenir ma carte</i>). Sans votre carte Hello, vous ne serez pas en mesure d'utiliser le solde ou les soldes que vous avez obtenu. Notez que vous pourrez toujours procéder à votre demande de carte plus tard en allant dans la section <i>Mon compte</i>. Prenez note qu'une fois la demande de carte complétée, votre carte vous sera livrée par la poste dans les 7 à 10 jours ouvrables.",
      title: 'Votre carte Hello',
    },
    overlay_space_section: {
      email_label: 'Adresse courriel',
      phone_label: 'Téléphone',
      website_label: 'Site web',
    },
    page_not_found_view: {
      action: "Aller à la page d'accueil",
      message: "Désolé, la page n'existe pas",
    },
    pay_and_get_consent_modal: {
      action: "J'accepte",
      info_1: 'Votre adresse courriel',
      info_2: 'Votre prénom et nom de famille',
      info_3: 'Votre activité en lien avec les offres {space_label}',
      sub_title: "En procédant à l'achat ou l'activation d'une offre sur l'espace Hello - {space_label}, vous consentez à ce que {space_label} ait accès à l'information suivante dans le but d'améliorer votre expérience et la qualité des offres:",
      title: "Je suis d'accord",
    },
    pay_and_get_editable: {
      bonus: 'Bonification',
      bonus_notice: 'Expire le {date}',
      dormancy_hints: {
        html_text: "Après {delay} mois d'inactivité, des frais d'inactivité de {amount} par mois seront appliqués au solde jusqu'à ce que le solde soit utilisé à nouveau ou soit épuisé.",
        link_title: 'Comment ça marche?',
        title: 'Inactivité',
      },
      dormancy_notice: "Frais d'inactivité à partir de {date}",
      error_payment_over_allowed_for_user: 'Le maximum permis par utilisateur pour cette offre {offer_branding_label} est de {limit_amount}. Il vous reste {remaining_amount} inutilisé pour cette offre et vous ne pouvez pas dépasser cette limite.',
      pay: 'Payez',
      pay_notice: 'Transfert à Mon argent le {date}',
      receive: 'Obtenez',
    },
    pay_and_get_editable_input: {
      label: 'Montant avec symbol',
      min_max: 'Min. ${min_amount}, max. ${max_amount}',
    },
    pay_and_get_offer_payment: {
      allow_communication: "Permettre à {partner} de m'envoyer des communications et promotions par courriel",
      credit_card_amount: 'Par carte de crédit',
      description: 'Vous pouvez utiliser jusqu’à {offer_total} de votre compte <i>Mon argent</i> pour effectuer votre paiement. Le solde restant, s’il y a lieu, sera à payer par carte de crédit.',
      detail_title: 'Détail du paiement',
      heading_credit_card: 'Carte de crédit',
      heading_my_cash: 'Compte <i>Mon argent</i><span>{my_cash_amount_max}</span>',
      hint: 'Min. {min_amount}$, max. {max_amount}$',
      my_cash_amount: 'Crédits <i>Mon argent</i>',
      open_purse_remaining: 'Il vous restera {open_purse_new_total} dans votre compte <i>Mon argent</i>.',
      pay: 'Payer',
      pay_with_my_cash: 'Utiliser <i>Mon Argent</i>',
      payment_method_title: 'Effectuer le paiement',
      title: "Complétez l'achat de l'offre",
    },
    pay_and_get_purchase_type: {
      for_someone_else: {
        label: 'Pour être distribué à des proches',
        title: 'Donner en cadeau',
        warning: 'À venir bientôt',
      },
      for_yourself: {
        at_your_name: 'Carte Hello à votre nom',
        label: 'Le solde sera déposé sur votre carte Hello',
        select_card: 'Choisissez sur laquelle de vos cartes Hello sera déposée l’offre',
        title: 'Se faire plaisir',
      },
      title: "Type d'achat",
    },
    pay_and_get_success: {
      sub_title: 'Votre compte a été mis à jour et un reçu vous a été envoyé par courriel!',
      title: 'Le paiement a été complété avec succès!',
    },
    payment_crowdfunding_feature: {
      option_anonymous: {
        label: 'Contribution anonyme',
        text: 'Votre nom ne sera pas affiché',
      },
      option_visible: {
        label: 'Contribution visible',
        text: 'Votre nom sera affiché',
      },
      title: 'Visibilité de votre contribution sur le site {site}',
    },
    payment_detail_final: {
      label: 'À payer',
    },
    payment_detail_use_my_money: {
      credit: 'Crédit',
      label: 'Mon argent',
    },
    payment_elements: {
      gst: 'TPS - {taxNumber}',
      sales_tax: 'TVQ - {taxNumber}',
      subscription: 'Abonnement mensuel',
      tax: 'Taxes',
    },
    payment_form_v2: {
      tokenization_error: 'Erreur de Tokenization: {message}',
    },
    payment_gateway_offline_modal: {
      action: 'ok',
      text:
        "Nous ne pouvons pas accepter les paiements pour le moment en raison d'un problème avec notre processeur de paiement. Veuillez nous écrire à support@hellocard.ca et nous vous informerons dès que le service sera rétabli. Merci de votre compréhension.",
      title: 'Désolé',
    },
    payment_new_card: {
      save: 'Sauvegarder ma carte',
      saved_card: 'Utiliser une carte sauvegardée',
    },
    payment_saved_card: {
      other_card: 'Saisir manuellement une autre carte',
    },
    publisher_card: {
      btn_available: 'Choisir',
      btn_deactivated: 'Indisponible',
      btn_sold_out: 'Épuisée',
    },
    publisher_small_card: {
      chose_offer_button: 'Choisir',
      see_offer_button: "Voir l'offre",
      see_stores_link: 'Voir détails et commerces admissibles',
    },
    purchase_sign_in_overlay: {
      error_offer_closed: 'Terminée',
      error_offer_coming_soon: 'Bientôt disponible',
      error_offer_sold_out: 'Épuisée',
    },
    recently_visited_widget: {
      show_less: 'Afficher moins',
      show_more: 'Afficher plus',
      title: 'Espaces Hello récemment visités',
    },
    redeem_flow_info: {
      step_1: {
        image_alt_text: "Icône de formulaire d'inscription vide",
        info_text: 'Connectez-vous ou inscrivez-vous ci-dessous.',
      },
      step_2: {
        image_alt_text: "Icône d'un formulaire rempli, censuré, contenant le code d'activation",
        info_text: "Saisissez votre code d'activation à 8 caractères",
      },
      step_3: {
        image_alt_text: 'Une carte Hello dans une enveloppe',
        info_text: "Procurez-vous votre carte Hello rechargeable si vous n'en avez pas déjà une.",
      },
      step_4: {
        image_alt_text: 'Icône de lecteur de carte de crédit',
        info_text: 'Dépensez votre solde avec votre carte Hello dans les commerces admissibles.',
      },
      subtitle: 'Votre compte Hello vous donne accès à vos achats, à vos soldes et à notre service à la clientèle.',
      title: 'Connectez-vous pour continuer',
    },
    region_selection: {
      modal: {
        language: {
          en: 'EN',
          fr: 'FR',
        },
        no_region: 'Aucune région spécifique, tout le Canada me convient!',
        title: 'Veuillez choisir votre région',
      },
      regions: {
        ab: 'Alberta',
        ca: 'Canada',
        nb: 'Nouveau-Brunswick',
        on: 'Ontario',
        qc: 'Québec',
        undefined: '-',
      },
    },
    register_form: {
      action: "S'inscrire",
      description: "<b>Vous n'avez pas encore de compte Hello?</b> Créez votre compte maintenant et dites Hello à plus d'argent.",
      email_field: 'Votre adresse courriel',
      have_account: {
        1: 'Vous avez déjà un compte Hello? ',
        2: 'Se connecter',
        3: ' maintenant.',
      },
      title: "S'inscrire à Hello",
    },
    register_modal: {
      action: "S'inscrire",
      confirm: 'Validez votre adresse courriel',
      description: 'Créez votre compte et protégez-le avec un mot de passe de votre choix.',
      email_field: 'Votre adresse courriel',
      password_field: 'Choisissez un mot de passe',
      title: 'Mon compte Hello',
    },
    renew_card_modal: {
      action: 'Envoyer',
      apartment_number: {
        label: 'Appt.',
      },
      city: {
        hints: 'Maximum 50 caractères',
        label: 'Ville*',
      },
      civic_number: {
        label: 'N° civique* ',
      },
      description_classic:
        'Choisissez le NIP de votre carte et vérifiez votre adresse. Une nouvelle carte avec les instructions d’activation vous sera envoyée à cette adresse. Tous vos soldes actuels seront déposés sur cette nouvelle carte au moment de son activation.',
      description_instant: 'Vérifiez votre adresse. Une nouvelle carte avec les instructions d’activation vous sera envoyée à cette adresse. Tous vos soldes actuels seront déposés sur cette nouvelle carte au moment de son activation.',
      mobile: {
        hints: '+1 (123) 123-1234',
        label: 'N° de tél. cellulaire',
      },
      pin_card: {
        hints: 'Exactement 4 chiffres',
        label: 'NIP de la carte*',
      },
      postal_code: {
        hints: 'X9X 9X9',
        label: 'Code postal*',
      },
      province: {
        label: 'Province*',
      },
      separator_your_address: 'Modifiez ou confirmez votre adresse',
      separator_your_card: 'Choisissez le NIP de votre nouvelle carte',
      street_name: {
        hints: 'Maximum 50 caractères',
        label: 'Nom de rue*',
      },
      title: 'Renouveler ma carte',
    },
    restricted_to_member_badge: {
      text: 'OFFRE PRIVILÈGE',
    },
    restricted_to_members_modal: {
      auth_cta: 'fermer',
      auth_text: "Cette offre est réservée exclusivement aux membres de l'espace Hello - {space}. Pour devenir membre, vous devez avoir acheté une offre ouverte à tous ou avoir activé une carte-cadeau.",
      auth_title: 'Vous n’avez pas accès à cette offre',
      no_auth_cta: 'se connecter',
      no_auth_text: "Vous devez vous connecter à votre compte Hello afin de profiter des offres exclusives aux membres de l'espace Hello - {space}.",
      no_auth_title: 'Vous devez être connecté',
    },
    shared: {
      genders: {
        female: 'Femme',
        male: 'Homme',
        non_binary: 'Non-Binaire / Troisième genre',
      },
      provinces: {
        AB: 'Alberta',
        BC: 'Colombie-Britannique',
        MB: 'Manitoba',
        NB: 'Nouveau-Brunswick',
        NL: 'Terre-Neuve-et-Labrador',
        NS: 'Nouvelle-Écosse',
        NT: 'Territoires du Nord-Ouest',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Île-du-Prince-Édouard',
        QC: 'Québec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      },
    },
    single_publisher_consumer_gift_certificate_offer: {
      anchor_menu: {
        corporate_desktop: 'Volet corporatif',
        corporate_mobile: 'Corporatif',
        how_it_works_desktop: 'Comment ça marche',
        how_it_works_mobile: 'Fonctionnement',
        where_to_use_desktop: 'Commerces admissibles',
        where_to_use_mobile: 'Commerces',
      },
      call_to_action: 'Activer ma carte-cadeau',
      call_to_action_consumer_gift_card: 'Acheter maintenant',
      destination_list: 'Découvrez toutes nos destinations magasinage',
      powered_by: 'Programme de carte-cadeau propulsé par',
      see_store: 'Voir les {storeCount} commerces admissibles',
    },
    small_card: {
      external_website: 'Visiter le site web (ce bouton ouvre une nouvelle fenêtre)',
      panier_bleu: "Panier Bleu - Faisons rouler l'économie d'ici",
      voucher: 'Carte-cadeau',
    },
    small_card_base_status_label: {
      closed: "L'offre est terminée",
      coming_soon: "L'offre sera disponible le {date}",
      coming_soon_no_date: "L'offre sera disponible bientôt",
      expiration: "L'offre expire le {date}",
      no_expiration: '',
      sold_out: "L'offre est épuisée",
    },
    space_offer_publisher_view: {
      info_bonus: 'Choisissez parmi les options ci-bas',
      info_gift_certificate: "Choisissez l'option pour laquelle vous désirez obtenir votre carte-cadeau",
    },
    stay_tuned_small_card: {
      description: 'Plusieurs nouvelles offres vous seront dévoilées très bientôt.',
      title: "Restez à l'affût!",
    },
    store_base_status_indicator: {
      status: {
        unready: "Ce commerce n'est pas encore activé",
      },
    },
    store_card: {
      online: 'En ligne',
      status: {
        ready: 'Vous pouvez utiliser votre carte à ce commerce.',
        undefined: 'Vous pouvez utiliser votre carte à ce commerce.',
        unready: "Le commerce n'a pas encore été activé (à venir).",
      },
      visit_web_site_place: 'Visiter le site web',
      visit_web_site_web: 'Aller sur le site web transactionnel',
    },
    store_card_big: {
      address_label: 'Adresse',
      back_action: 'Retour',
      phone_label: 'Téléphone',
      website_label: 'Site web',
    },
    store_list: {
      destination_all: 'Toutes les destinations',
      info_label: 'Commerces admissibles',
      search_store_placeholder: 'Rechercher un commerce',
      store_filter_count: 'Aucun commerce trouvé | {count} commerce trouvé | {count} commerces trouvés',
      store_filter_empty: 'Malheureusement, aucun commerce ne correspond à votre recherche.',
    },
    subscription_payment: {
      allow_communication: "Permettre à {partner} de m'envoyer des communications et promotions par courriel",
      detail_title: 'Détail du paiement',
      pay: 'Payer',
      payment_method_title: 'Effectuer le paiement',
      title: "Complétez le paiement de l'abonnement",
    },
    subscription_status: {
      cancel_cta: 'Me désabonner',
      manage_cta: 'Gérer mon abonnement',
      plan: 'Abonnement',
      plan_fee: '{amount} / mois',
      status: 'Statut',
      status_options: {
        active: 'Active',
        cancelled: 'Annulé',
      },
    },
    subscription_warning_modal: {
      auth_cta: "s'abonner",
      auth_text: "Vous devez être abonné à l'espace Hello - {space} au coût mensuel de {amount} / mois pour profiter des offres exclusives aux membres {space}.",
      auth_title: 'Abonnement',
      no_auth_cta: 'se connecter',
      no_auth_text: 'Vous devez être connecté pour profiter des offres exclusives aux membres {space}.',
      no_auth_title: 'Connectez-vous',
    },
    success_bonus_offer_no_card: {
      cta_close: 'Fermer la fenêtre et poursuivre mes achats',
      sub_title: "Une preuve d'achat vous a été envoyé par courriel et votre compte Hello a été mis à jour. La prochaine étape est d'obtenir votre carte Hello pour utiliser votre solde dans les commerces.",
      title: 'Le paiement a été complété avec succès!',
    },
    success_bonus_offer_with_card: {
      cta_close: 'Fermer la fenêtre et poursuivre mes achats',
      cta_my_account: 'Aller à la section&nbsp;<i>Mon compte</i>&nbsp;pour consulter mes soldes',
      sub_title: "Une preuve d'achat vous a été envoyé par courriel et votre compte Hello a été mis à jour. Vous pouvez consulter vos soldes dans la section <i>Mon compte</i> ou dans l'appli Hello.",
      title: 'Le paiement a été complété avec succès!',
    },
    success_gift_certificate_offer_no_card: {
      cta_close: 'Fermer la fenêtre et poursuivre mes achats',
      sub_title: "Votre compte Hello a été mis à jour. La prochaine étape est d'obtenir votre carte Hello pour utiliser votre solde dans les commerces.",
      title: 'La carte-cadeau a été réclamée avec succès!',
    },
    success_gift_certificate_offer_with_card: {
      cta_close: 'Fermer la fenêtre et poursuivre mes achats',
      cta_my_account: 'Aller à la section&nbsp;<i>Mon compte</i>&nbsp;pour consulter mes soldes',
      sub_title: "Votre compte Hello a été mis à jour, vous pouvez consulter vos soldes dans la section <i>Mon compte</i> ou dans l'appli Hello.",
      title: 'La carte-cadeau a été réclamée avec succès!',
    },
    success_instant_card_application: {
      description: 'Vous recevrez une carte Hello par la poste dans un délai de 7 à 10 jours ouvrables.',
      title: 'Votre demande a été soumise avec succès!',
    },
    success_subscription_no_card: {
      cta_close: 'Fermer la fenêtre et profiter de mon abonnement',
      cta_purchase: "Continuer avec l'achat de {offer}",
      sub_title: "Vous pouvez maintenant profiter des offres de l'espace Hello - {space}.",
      title: 'Le paiement a été complété avec succès! ',
    },
    success_subscription_with_card: {
      cta_close: 'Fermer la fenêtre et profiter de mon abonnement',
      cta_purchase: "Continuer avec l'achat de {offer}",
      sub_title: "Vous pouvez maintenant profiter des offres de l'espace Hello - {space}.",
      title: 'Le paiement a été complété avec succès! ',
    },
    unsubscribe_modal: {
      action: 'Me Désabonner',
      info_1: "Ne plus avoir accès aux offres de type bonification de l'espace Hello - {space}",
      info_2: "Perdre toute bonification obtenue dans l'espace Hello - {space}",
      text: "En procédant au désabonnement à l'espace Hello - {space}, vous consentez à :",
      title: 'Désabonnement',
    },
    update_email_modal: {
      action: 'Envoyer',
      cancel: 'Annuler',
      confirm: 'Validez votre adresse courriel',
      description: "Un courriel avec un lien de vérification vous sera envoyé à la nouvelle adresse courriel. N'oubliez pas de vérifier votre dossier d'indésirables ou tout autre dossier secondaire de votre application de gestion de courrier.",
      email_field: 'Nouvelle adresse courriel',
      title: 'Modifier mon adresse courriel',
    },
    user_profile: {
      change_email: 'Modifier mon adresse courriel',
      change_password: 'Modifier mon mot de passe',
      manage_btn: 'Gérer',
      my_account: 'Mon compte',
      my_money: 'Mon argent',
      renew_card_success: 'La demande de renouvellement de carte été envoyé avec succès.',
      reset_password_modal_action: 'Envoyer le lien',
      reset_password_modal_cancel: 'Annuler',
      reset_password_modal_close: 'Fermer',
      reset_password_modal_expiration_warning: 'Veuillez noter que le lien expirera dans une heure.',
      reset_password_modal_text: 'Un lien vous sera envoyé à votre adresse courriel pour de modifier votre mot de passe.',
      reset_password_modal_title: 'Modifier votre mot de passe',
      reset_password_success: 'Le courriel de modification de mot de passe a été envoyé avec succès',
      reset_password_success_title: 'Courriel envoyé avec succès',
      update_email_modal_action: 'Modifier',
      update_email_success: 'Le courriel de vérification a été envoyé avec succès à la nouvelle adresse courriel.',
    },
    verify_email_view: {
      back_action: "Aller à la page d'accueil",
      error_message: "Désolé, votre adresse courriel n'a pu être vérifiée",
      expired_message: "Désolé, votre adresse courriel n'a pu être vérifiée. Le lien de vérification a expiré.",
      success_message: 'Votre adresse courriel a été vérifiée avec succès!',
    },
    voucher_history: {
      actual_balance: 'Solde actuel',
      title: 'Historique',
    },
    vue_virtual_grid: {
      load_more: 'Charger plus',
    },
  },
  errors: {
    http: {
      account_no_access: "Vous n'avez plus l'accès a cette entreprise",
      account_not_a_member_of_the_partner: "Le compte n'est pas membre de l'espace",
      account_not_found: "Le compte ne semble pas avoir les permissions necessaires pour effectuer l'action",
      account_not_verified: 'Une erreur est survenue lors de la vérification de votre e-mail',
      account_update_failed: "Le compte n'a pas été mis à jour",
      acquiring_context_offer_invalid_status: "Cette offre n'est plus disponible.",
      acquiring_context_publisher_invalid_status: "Cette offre n'est plus disponible.",
      activation_code_activation_not_enabled: 'Désolé, cette carte-cadeau n’a pas été pré-activée.',
      activation_code_already_redeemed: "Ce code d'activation a déjà été réclamé",
      activation_code_not_active: "Ce code d'activation n'est pas valide",
      activation_code_not_valid: "Ce code d'activation n'est pas valide",
      already_activated: 'Votre carte est déjà activée.',
      already_subscribed_to_plan: 'Vous êtes déjà abonné veuillez recharger la page',
      amount_greater_than_purchase_limit_max: "Le montant d'achat est invalide (supérieur au maximum requis par achat).",
      amount_less_than_purchase_limit_min: "Le montant d'achat est invalide (inférieur au minimum requis par achat).",
      amount_lower_than_offer_minimum: "Le montant d'achat est invalide (inférieur au minimum requis par achat).",
      amount_out_of_fees_inventory_available_amount: 'Une erreur est survenue lors du traitement de votre commande',
      amount_out_of_inventory_available_amount: "Le montant d'achat excède le montant disponible pour l'offre. Veuillez essayer un plus petit montant.",
      amount_out_of_offer_step_bound: "Le montant d'achat est invalide.",
      'auth_auth_internal-error': 'Adresse courriel ou mot de passe invalide',
      'auth_auth_invalid-email': "L'adresse courriel est invalide",
      'auth_auth_too-many-requests': "Trop de requête. S'il-vous-plait attendre quelques minutes et essayer de nouveau.",
      'auth_auth_user-disabled': 'Votre compte est désactivé',
      'auth_auth_user-not-found': 'Adresse courriel ou mot de passe invalide',
      'auth_auth_wrong-password': 'Adresse courriel ou mot de passe invalide',
      cannot_be_activated: 'Cette carte ne peut pas être activée.',
      card_invalid_activation_code: "Le code d'activation est invalide",
      card_invalid_status: "La carte n'est pas dans le bon état",
      cardholder_already_connected: 'Une carte Hello est déjà connectée à votre compte',
      email_already_taken_other_account: 'Un compte existe déjà avec cette adresse courriel',
      email_is_not_related_business: "Vous n'avez pas accès a cette entreprise",
      email_validation_expired: "Désolé, votre adresse courriel n'a pu être vérifiée",
      email_verification_jwt_invalid: "Désolé, votre adresse courriel n'a pu être vérifiée",
      exceed_offer_account_inventory_limit: 'Le maximum permis par utilisateur pour cette offre {offer_branding_label} est de {limit_amount}. Il vous reste {remaining_amount} inutilisé pour cette offre et vous ne pouvez pas dépasser cette limite.',
      exceed_offer_publisher_inventory_limit: 'Le maximum permis par utilisateur pour cette offre {offer_branding_label} est de {limit_amount}. Il vous reste {remaining_amount} inutilisé pour cette offre et vous ne pouvez pas dépasser cette limite.',
      firebase_email_invalid: "L'adresse courriel est invalide",
      firebase_email_taken: 'Un compte existe déjà avec cette adresse courriel',
      incompatible_interaction_jwt: "Le jeton n'est pas valide.  Veuillez recharger la page.",
      incompatible_interaction_jwt_offers: "Le jeton n'est pas valide pour les offres.  Veuillez recharger la page et essayer de nouveau.",
      incompatible_interaction_jwt_publishers: "Le jeton n'est pas valide pour les publisher.  Veuillez recharger la page et essayer de nouveau.",
      incompatible_token_owner: "Le jeton n'est pas valide. Veuillez recharger la page.",
      incorrect_email_status: 'Désolé, une erreur inconnue est survenue (statut de courriel)',
      instant_card_activation_not_enabled: 'Désolé, cette carte n’a pas été pré-activée.',
      instant_card_status_not_pending: 'Cette carte ne peut pas être activée.',
      invalid_instant_card_information: 'Les informations de carte que vous avez saisi ne sont pas valides.',
      invalid_offer_consumer_status: "L'offre n'est pas disponible.",
      invalid_offer_publisher_status: "Cette offre n'est plus disponible. Veuillez actualiser la page.",
      invalid_offer_status: "Cette offre n'est pas disponible",
      invalid_offer_type: "Cette offre n'est pas disponible pour les commandes de ce type",
      invalid_token: 'Jeton du changement de mot de passe est invalid',
      inventory_not_found: "Désolé, une erreur est survenue. L'offre est mal configurée (inventaire). Veuillez svp contacter le service à la clientèle.",
      jwt_not_valid: "Le jeton n'est pas valide",
      jwt_verify_failed: "Le jeton n'est pas valide",
      linked_consumer_gift_card_voucher_cancelled: 'Désolé, la carte-cadeau ne peut être activée puisqu’il a été annulée par son acheteur.',
      no_available_amount_of_inventory: 'Désolé, l’offre est épuisée et il n’est plus possible d’acheter des cartes-cadeaux.',
      no_available_offer: "Désolé, cette carte ne peut pas être activée. Veuillez contacter notre service à la clientèle à support@hellocard.ca pour obtenir de l'assistance.",
      no_cards_to_activate: 'Oups, votre carte a déjà été activée! Vous pouvez fermer la fenêtre et utiliser votre carte pour faire des achats en magasin.',
      offer_bad_status_closed: "Désolé, l'offre n'est plus disponible.",
      offer_is_closed: "Impossible d'obtenir le contexte pour une offre terminée",
      offer_is_draft: "Impossible d'obtenir le contexte pour une offre en brouillon",
      offer_not_found: "Désolé, une erreur est survenue. L'offre est introuvable. Veuillez svp contacter le service à la clientèle.",
      offer_publisher_not_found: "Désolé, une erreur est survenue. L'offre est introuvable. Veuillez svp contacter le service à la clientèle.",
      offer_status_not_available: "Désolé, l'offre n'est plus disponible.",
      order_corporate_account_not_found: 'Incapable de trouver votre commande',
      payment_method_used_for_subscription: 'La carte ne peut être supprimée. La carte est présentement utilisée pour payer un abonnement sur Hello.',
      paysafe_card_expiration_failure: "Erreur de paiement, la date d'expiration de la carte est invalide ({id}).",
      paysafe_card_expired: 'Erreur de paiement, la carte est expirée ({id}).',
      paysafe_card_info_invalid: 'Erreur de paiement, les informations de carte sont invalides ({id}).',
      paysafe_card_number_invalid: 'Erreur de paiement, le numéro de la carte est invalide ({id}).',
      paysafe_card_restricted: "Erreur de paiement, la transaction sur cette carte n'est pas permise. Essayer une autre carte ({id}).",
      paysafe_cvv_failure: 'Erreur de paiement, le cvv de carte est invalide ({id}).',
      paysafe_generic_decline: 'Erreur de paiement, veuillez corriger vos informations de carte et réessayer ({id}).',
      paysafe_insufficient_funds: 'Erreur de paiement, les fonds sont insuffisants sur la carte ({id}).',
      paysafe_limit_refresh_page: 'Erreur de paiement, veuillez rafraîchir la page et réessayer ({id}).',
      paysafe_other_error: 'Erreur de paiement, veuillez réessayer ou contacter notre service à la clientèle ({id}).',
      paysafe_retry: 'Erreur de paiement, veuillez rafraîchir la page et réessayer ({id}).',
      reached_account_redeem_limit_day: 'Désolé, la carte-cadeau n’a pu être activée. Pour des raisons de sécurité, nous ne permettons pas de activer au-delà de 15 cartes-cadeaux par jour. Veuillez réessayer demain.',
      recaptcha_failed: 'Le jeton de validation Google recaptcha est erroné',
      square_card_expired: 'Erreur de paiement, la carte est expirée ({payment_id}).',
      square_card_not_supported: 'Erreur de paiement, la carte n’est pas supportée ({payment_id}).',
      square_cvv_failure: 'Erreur de paiement, le cvv de carte est invalide ({payment_id}).',
      square_expiration_failure: 'Erreur de paiement, la carte est expirée ({payment_id}).',
      square_generic_decline: 'Erreur de paiement, veuillez corriger vos informations de carte et réessayer ({payment_id}).',
      square_insufficient_funds: 'Erreur de paiement, les fonds sont insuffisants sur la carte ({payment_id}).',
      square_invalid_expiration: 'Erreur de paiement, la carte est expirée ({payment_id}).',
      square_other_error: 'Erreur de paiement, veuillez réessayer ou contacter notre service à la clientèle ({payment_id}).',
      subscription_not_found: "Erreur avec l'abonnement actuel. Veuillez recharger la page.",
      token_uuid_failed: "Le lien a expiré ou n'est pas valide. Si vous rencontrez des difficultés pour récupérer votre mot de passe, n'hésitez pas à nous contacter à support@hellocard.ca.",
      transaction_token_context_invalid: 'Une erreur est survenue lors du traitement de votre commande',
      user_already_exists: 'Un compte avec cette adresse courriel existe déjà',
      user_already_has_card: 'Il y a déjà une carte associée à ce compte',
      user_already_has_pending_application: 'Il y a déjà une demande de carte en cours associée à ce compte',
      user_not_found: "Le compte n'existe pas",
    },
    refresh_required: "Désolé! Une erreur s'est produite, veuillez actualiser la page et réessayer  ({code})",
    system: {
      system_bad_gateway: "Problème d'API inconnu",
      system_forbidden: 'Accès interdit: {message}',
      system_resource_not_found: 'Introuvable',
      system_unauthorized: 'Non autorisé: {message}',
      validation_error: 'Erreur de validation: {message}',
    },
    systemError: 'ERREUR SYSTÈME: ',
  },
  filters: {
    money: '{number}$',
    tax: {
      gst: 'TPS',
      sales_tax: 'TVQ',
    },
  },
  page_title: {
    change_password: 'Modifier mot de passe',
    corpo: 'Programme de cartes-cadeaux pour entreprises',
    demo: 'DEV - Demo',
    home: 'Accueil',
    instant_card_activation: 'Activation',
    my_account: 'Mon compte',
    offer_activation: 'Activation',
    page_not_found: 'Page introuvable',
    styleguide: 'DEV - Styleguide',
  },
  validation: {
    consent: "Besoin d'approuver",
    fields: {
      activation_code: "Code d'activation",
      birth_date: 'Date de naissance',
      card_ref_id: 'Id de référence de la carte',
      city: 'Ville',
      email: 'Adresse courriel',
      first_name: 'Prénom',
      gender: 'Genre',
      gift_certificate_code: "Code d'activation",
      last_4_digits: 'Dernier 4 chiffres du numéro de carte',
      last_name: 'Nom de famille',
      mobile: 'Numéro de mobile',
      name: 'Nom',
      number_of_employees: "Nombre d'employés",
      password: 'Mot de passe',
      phone: 'Numéro de téléphone',
      pinCard: 'NIP de la carte',
      postal_code: 'Code Postal',
      province: 'Province',
      sender_name: 'Votre nom',
      street: 'No civique et rue',
    },
    invalid: {
      activation_code: 'Exactement 6 chiffres',
      card_pin: 'Exactement 4 chiffres',
      email: 'Est invalide',
      email_match: 'Ne correspond pas à votre adresse courriel',
      factor: '{value}*{otherFactor} > {maxMultiple}',
      gift_certificate: "Code d'activation invalide",
      last_4_digits: 'Format invalide.',
      maxLength: 'Maximum {maxLength} caractères',
      maxLines: 'Maximum {maxLines} lignes',
      maxValue: 'Ne peut être plus que {maxValue}',
      minValue: 'Ne peut être moins que {minValue}',
      mobile: 'Format invalide du numéro de téléphone mobile',
      password: 'Minimum 8 caractères',
      phone: 'Numéro de téléphone est invalide.',
      positive_float: "Le nombre n'est pas un chiffre à virgule valid",
      positive_integer: "Le nombre n'est pas un nombre entier positif",
      postal_code: 'Format Invalide. ex: A0A 0A0',
      postal_code_format: 'Format Invalide. ex: A0A 0A0',
      token: 'Format invalide.',
    },
    required: 'Est requis',
  },
};
