import { render, staticRenderFns } from "./AccountInfoBarBase.vue?vue&type=template&id=68ef86f3&scoped=true"
import script from "./AccountInfoBarBase.vue?vue&type=script&lang=js"
export * from "./AccountInfoBarBase.vue?vue&type=script&lang=js"
import style0 from "./AccountInfoBarBase.vue?vue&type=style&index=0&id=68ef86f3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ef86f3",
  null
  
)

export default component.exports