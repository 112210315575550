var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"consumer-gift-card-payment-success"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.title')))]),_c('div',{staticClass:"h-text-2"},[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.subtitle')))])]),_c('div',{staticClass:"order-action-box"},[_c('p',{staticClass:"h-label-1"},[_vm._v(_vm._s(_vm.textAndOrderNumber))]),(!_vm.voucherReady)?_c('div',{staticClass:"voucher-not-ready-container"},[(!_vm.pollTimeExceeded && !_vm.inError)?_c('v-progress-circular',{attrs:{"size":100,"width":7,"indeterminate":"","color":"primary"}}):_c('h-btn',{attrs:{"to":{ name: 'my-account' }}},[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.redirect_consumer_gifts')))])],1):_c('div',[(_vm.orderIsCompleted)?_c('h-action-layout',{staticStyle:{"flex-direction":"row"}},[_c('consumer-gift-card-download-pdf-modal',{attrs:{"voucher-id":_vm.voucherId,"activation-code-id":_vm.activationCodeId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h-btn',_vm._g(_vm._b({attrs:{"outlined":"","large":""}},'h-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-wallet-giftcard")]),_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_download_pdf_modal.action_title')))],1)]}}],null,false,1172091093)}),_c('consumer-gift-card-send-by-email-modal',{attrs:{"voucher-id":_vm.voucherId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h-btn',_vm._g(_vm._b({attrs:{"outlined":"","large":""}},'h-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-outline")]),_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_send_by_email_modal.action_title')))],1)]}}],null,false,4040294852)})],1):_vm._e(),_c('h-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderIsCompleted),expression:"orderIsCompleted"}],staticClass:"download-receipt",attrs:{"large":"","inverted":"","loading":_vm.receiptWorking},on:{"click":_vm.downloadReceipt}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","center":""}},[_vm._v("mdi-open-in-new")]),_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.download_receipt'))+" ")],1)],1),(_vm.orderIsCompleted)?_c('p',{staticClass:"h-text-2"},[_vm._v(" "+_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_my_account'))),_c('i',[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_my_account_italic')))])]):_c('p',{staticClass:"h-text-2 pending-order-information"},[_vm._v(" "+_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_my_account_pending_order'))),_c('i',[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_my_account_italic')))])]),_c('div',{staticClass:"link-section"},[_c('merchants-modal',{staticClass:"link-merchants",attrs:{"offer_id":_vm.offerId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h-link',_vm._g(_vm._b({staticClass:"link-merchants",attrs:{"large":""}},'h-link',attrs,false),on),[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_merchants')))])]}}])}),(_vm.voucherReady)?_c('h-link',{staticClass:"link-account",attrs:{"large":"","to":{ name: 'my-account' }},domProps:{"innerHTML":_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_my_account_2'))}}):_vm._e(),(_vm.voucherReady)?_c('h-link',{staticClass:"link-cancel",attrs:{"large":""},on:{"click":_vm.closeOverlay}},[_vm._v(_vm._s(_vm.$t('components.consumer_gift_card_payment_success.cta_cancel')))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }