import Vue from 'vue';

/*
// NOTE: Debug
function sleep(ms = 3000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
} */

function extractFileNameFromHeaders(headers) {
  return headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .slice(1, -1);
}

const service = {
  async createBusiness(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/businesses/by-account`, payload);
    return response.data.data;
  },
  async updateBusiness(business_id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/v1/businesses/${business_id}`, payload);
    return response.data.data;
  },
  async listBusinesses() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/businesses`);
    return response.data.data.items;
  },
  async acquireCorporateOrderContext(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/corporate-orders/acquiring-context`, payload);
    return response.data.data;
  },
  async createPurchaseCorporateOrder(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/corporate-orders/purchase`, payload);
    return response.data.data;
  },
  // async createCorporateOrderIntent(payload) {
  //   const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/corporate-orders/intent`, payload);
  //   return response.data.data;
  // },
  async getCorporateOrderStatus(corporate_order_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/corporate-orders/${corporate_order_id}/status-completed`);
    return response.data.data;
  },
  async downloadActivationCodesBatchExcel(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/activation-code-batches/${id}/export/excel`, { lang: language }), {
      responseType: 'blob',
    });

    return { content: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async downloadActivationCodesBatchPdf(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/activation-code-batches/${id}/export/pdf`, { lang: language }), {
      responseType: 'blob',
    });

    return response.data;
  },
  async downloadActivationCodesBatchInfosPdf(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/activation-code-batches/${id}/export/pdf-infos`, { lang: language }), {
      responseType: 'blob',
    });

    return response.data;
  },
  async downloadActivationCodesBatchZip(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/activation-code-batches/${id}/export/zip`, { lang: language }), {
      responseType: 'blob',
    });

    return response.data;
  },
  async downloadOrderInvoice(corporate_order_id, lang = 'en') {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v2/corporate-orders/${corporate_order_id}/invoice`, { lang }), {
      responseType: 'blob',
    });

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
};

function generatePath(startPath, { lang }) {
  let path = startPath;
  if (lang) {
    path += '?';
  }

  const queryStrings = [];
  if (lang) {
    queryStrings.push(['lang', lang]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
