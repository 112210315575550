import _filter from 'lodash/filter';
import _get from 'lodash/get';
import Vue from 'vue';
import { secureStorage } from '@/utils/secure-storage';

/*
// NOTE: Debug
async function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}*/

const FOCUSED_REGION_OFFER_LIMIT = 6;
const FOCUSED_STATUS_OFFER_LIMIT = 9;
const DEFAULT_SECTION_OFFER_LIMIT = 3;

const HOME_CATEGORIES = ['mixed', 'food_groceries', 'restaurants_bars', 'leisure_getaways', 'fashion_style', 'home_cars', 'miscellaneous'];

export default {
  namespaced: true,
  state: {
    currentOffer: { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } },
    currentOfferPublisher: { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } },
    offers: [],
    giftCertificateConsumerEnabledOfferList: [],
    offerPublishers: [],
    currentOfferPublishersCategories: [],
    currentSpaceOfferCategoriesList: [],
    offerCorporateDetails: undefined,
    unfilterableRegions: ['ca'],
    regionOptions: ['ca', 'qc', 'on', 'nb', 'ab'],
    regionDisplayOptions: ['ca', 'ab', 'nb', 'on', 'qc'],
    currentRegion: secureStorage.getItem('hello::currentRegion') ? secureStorage.getItem('hello::currentRegion').toLowerCase() : undefined,
    statusSectionFilters: {
      available: ['available'],
      coming_soon: ['coming_soon'],
      missed: ['sold_out', 'closed'],
    },
    statusSectionBaseOfferLimit: FOCUSED_STATUS_OFFER_LIMIT,
  },
  getters: {
    // Offer
    currentOffer: (state) => state.currentOffer,
    currentOfferId: (state) => _get(state.currentOffer, 'id'),
    currentOfferIsBonus: (state) => _get(state.currentOffer, 'type') === 'bonus',
    currentOfferIsGift: (state) => _get(state.currentOffer, 'type') === 'gift_certificate',
    currentOfferName: (state, getters, rootState, rootGetters) => _get(state.currentOffer, `offer_branding.label.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferCategory: (state, getters, rootState, rootGetters) => _get(state.currentOffer, `category_name.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferRegion: (state, getters, rootState, rootGetters) => _get(state.currentOffer, `offer_branding.region_label.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferDescription: (state, getters, rootState, rootGetters) => _get(state.currentOffer, `offer_branding.description.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferShowcasesImage: (state) => _get(state.currentOffer, 'offer_branding.images.showcases', { fr: [], en: [] }),
    currentOfferBrandingFeaturePanierBleu: (state) => _get(state.currentOffer, 'offer_branding.features.panier_bleu', false),
    currentOfferRestrictedToMembers: (state) => _get(state.currentOffer, 'features.restricted_to_members', false),
    currentOfferStatus: (state) => _get(state.currentOffer, 'status'),
    currentOfferShowStoreList: (state) => _get(state.currentOffer, 'show_store_list', false),
    currentOfferAvailabilityAdjustedAvailPaymentAmount: (state) => _get(state.currentOffer, 'availability.inventory.adjusted_avail_payment_amount', 0),
    currentOfferAvailabilityAdjustedTotalPaymentAmount: (state) => _get(state.currentOffer, 'availability.inventory.adjusted_total_payment_amount', 0),
    currentOfferConsumerEnabled: (state, getters) => _get(state.currentOffer, 'consumer.enabled', false) && getters.currentOfferIsGift,
    currentOfferConsumerPurchasable: (state, getters) => getters.currentOfferConsumerEnabled && _get(state.currentOffer, 'consumer.purchasable', false) && getters.currentOfferStatus === 'available' && getters.currentOfferCtaPurchase,
    currentOfferConsumerPurchaseLimit: (state, getters) => _get(state.currentOffer, 'consumer.purchase_limit', { min: 0, max: 0 }),
    currentOfferCtaActivation: (state) => _get(state.currentOffer, 'cta.activation', true),
    currentOfferCtaPurchase: (state) => _get(state.currentOffer, 'cta.purchase', true),
    currentOfferUsedInventory: (state) => _get(state.currentOffer, 'availability.inventory.used_amount'),
    currentOfferTotalInventory: (state) => _get(state.currentOffer, 'availability.inventory.total_amount'),
    currentOfferEndDate: (state) => _get(state.currentOffer, 'availability.end_date'),
    currentOfferStartDate: (state) => _get(state.currentOffer, 'availability.start_date'),
    currentOfferIsSinglePublisher: (state) => state.currentOffer.is_single_publisher,
    currentOfferIncentiveStep: (state) => _get(state.currentOffer, 'incentive.step'),
    currentOfferIncentiveBonusDisplay: (state) => _get(state.currentOffer, 'incentive.bonus_display', 0),
    currentOfferIncentiveBonusPercent: (state) => _get(state.currentOffer, 'incentive.bonus_percent', 0), // Not used a lot
    currentOfferIncentivePurchaseLimit: (state) => _get(state.currentOffer, 'incentive.user_payment_limit', 0),
    currentOfferIncentiveMinimumPayment: (state) => _get(state.currentOffer, 'incentive.minimum_payment', 0),
    currentOfferIncentiveAdjustPurchaseLimit: (state) => _get(state.currentOffer, 'incentive.adjusted_user_payment_limit', 0),
    currentOfferDestinations: (state) => _get(state.currentOffer, 'destinations', []),

    // Offer Publisher
    currentOfferPublisher: (state) => state.currentOfferPublisher,
    currentOfferPublisherPublisherId: (state) => _get(state.currentOfferPublisher, 'publisher_id'),
    currentOfferPublisherName: (state, getters, rootState, rootGetters) => _get(state.currentOfferPublisher, `branding.label.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferPublisherDescription: (state, getters, rootState, rootGetters) => _get(state.currentOfferPublisher, `branding.description.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferPublisherCategory: (state, getters, rootState, rootGetters) => _get(state.currentOfferPublisher, `category_name.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferPublisherRegion: (state, getters, rootState, rootGetters) => _get(state.currentOfferPublisher, `branding.region_label.${rootGetters['ui/currentLocale']}`, ''),
    currentOfferPublisherShowcasesImage: (state) => _get(state.currentOfferPublisher, 'branding.images.showcases', { fr: [], en: [] }),
    currentOfferPublisherBrandingFeaturePanierBleu: (state) => _get(state.currentOfferPublisher, 'branding.features.panier_bleu', false),
    currentOfferPublisherOfferId: (state) => _get(state.currentOfferPublisher, 'offer_id'),
    currentOfferPublisherStatus: (state) => _get(state.currentOfferPublisher, 'status'),
    currentOfferPublisherUsedInventory: (state) => _get(state.currentOfferPublisher, 'inventory.used_amount'),
    currentOfferPublisherTotalInventory: (state) => _get(state.currentOfferPublisher, 'inventory.total_amount'),

    // Search options
    currentRegion: (state) => state.currentRegion,
    regionOptions: (state) => state.regionOptions,
    regionDisplayOptions: (state) => state.regionDisplayOptions,
    filterableRegions: (state) => _filter(state.regionOptions, (region) => !state.unfilterableRegions.includes(region)),
    currentRegionIsFilterable: (state, getters) => getters.filterableRegions.includes(state.currentRegion),
    offersList: (state) => state.offers,
    statusOptions: (state) => Object.keys(state.statusSectionFilters),
    statusSectionBaseOfferLimit: (state) => state.statusSectionBaseOfferLimit,
    currentSpaceOfferCategoriesList: (state) => state.currentSpaceOfferCategoriesList,
    homeOfferCategoriesList: () => HOME_CATEGORIES,

    giftCertificateConsumerEnabledOfferList: (state) => state.giftCertificateConsumerEnabledOfferList,

    // Publisher List
    offerPublishersCategoriesList: (state) => state.currentOfferPublishersCategories,
    currentOfferPublishersList: (state) => state.offerPublishers || [],

    // Offer Corpo Detail
    offerCorporateDetails: (state) => state.offerCorporateDetails,
  },
  mutations: {
    setCurrentRegion: (state, region) => {
      state.currentRegion = region;
    },
    setCurrentOfferPublishersCategories: (state, categories) => {
      state.currentOfferPublishersCategories = categories;
    },
    setCurrentSpaceOfferCategoriesList: (state, categories) => {
      state.currentSpaceOfferCategoriesList = categories;
    },
    setOffers: (state, offers) => {
      state.offers = offers;
    },
    setGiftCertificateConsumerEnabledOfferList: (state, offers) => {
      state.giftCertificateConsumerEnabledOfferList = offers;
    },
    setCurrentOffer: (state, offer) => {
      if (offer) {
        state.currentOffer = offer;
      } else {
        state.currentOffer = { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } };
      }
    },
    setOfferPublisher: (state, publisher) => {
      state.currentOfferPublisher = publisher;
    },
    setOfferPublishers: (state, publishers) => {
      state.offerPublishers = publishers;
    },
    reset: (state) => {
      state.currentOffer = { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } };
      state.currentOfferPublisher = { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } };
      state.offers = [];
      state.offerPublishers = [];
    },
    resetCurrentOffer: (state) => {
      state.currentOffer = { publisher_count: 0, offer_branding: { label: { fr: '', en: '' }, images: { showcases: { fr: [], en: [] } } } };
    },
    setOfferCorporateDetails: (state, corporate) => {
      state.offerCorporateDetails = corporate;
    },
  },
  actions: {
    updateRegion({ commit }, region) {
      secureStorage.setItem('hello::currentRegion', region);
      commit('setCurrentRegion', region);
    },
    async getOfferDetail({ commit, dispatch, state, rootState }, id) {
      const isSameOffer = state.currentOffer.id === id;
      if (isSameOffer) {
        commit('resetCurrentOffer');
      }
      const offerDetail = await Vue.prototype.$services.offer.getOffer(id);
      if (!offerDetail) {
        return null;
      }
      commit('setCurrentOffer', offerDetail);

      //If is same offer do not reset the payment amount
      if (!isSameOffer) {
        dispatch('payment/setPaymentAmountForPurchase', _get(offerDetail, 'incentive.default_payment', 0), { root: true });
      }
      if (!rootState.security.authenticated) {
        dispatch('payment/resetPaymentState', {}, { root: true });
      }
      return offerDetail;
    },
    async getOfferCorporateDetails({ commit }, id) {
      const offerCorporateDetails = await Vue.prototype.$services.offer.getOfferCorporateDetails(id);
      commit('setOfferCorporateDetails', offerCorporateDetails.corporate);
      return offerCorporateDetails.corporate;
    },
    async getOfferDetailBySlug({ commit, dispatch, state, rootState }, { slug, partnerId }) {
      const isSameOffer = state.currentOffer.slug === slug;
      if (isSameOffer) {
        commit('resetCurrentOffer');
      }

      const offerDetail = await Vue.prototype.$services.offer.getOfferBySlug(slug, partnerId);
      if (!offerDetail) {
        return null;
      }
      commit('setCurrentOffer', offerDetail);

      if (!isSameOffer) {
        dispatch('payment/setPaymentAmountForPurchase', _get(offerDetail, 'incentive.default_payment', 0), { root: true });
      }

      if (!rootState.security.authenticated) {
        dispatch('payment/resetPaymentState', {}, { root: true });
      }

      return offerDetail;
    },
    async getOfferPublisher({ commit }, { offer_id, publisher_id }) {
      const offerPublisher = await Vue.prototype.$services.offer.getOfferPublisher(offer_id, publisher_id);
      commit('setOfferPublisher', offerPublisher);
      return offerPublisher;
    },
    async getOfferPublishers({ commit }, { offer_id, locale = 'fr', category_to_include = undefined }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;
      const { items, item_count } = await Vue.prototype.$services.offer.getOfferPublishers(offer_id, locale, 1, 500, categories_to_include);
      commit('setOfferPublishers', items);
      return { items, item_count };
    },
    async getOfferPublisherCategoriesList({ commit }, { offer_id, locale = 'fr' }) {
      const items = await Vue.prototype.$services.offer.getOfferPublisherCategoriesList(offer_id, locale);
      commit('setCurrentOfferPublishersCategories', items);
    },
    async getSpaceOfferCategoriesList({ commit, rootState }, { locale = 'fr' }) {
      const items = await Vue.prototype.$services.offer.getSpaceOfferCategoriesList(rootState.space.currentPartnerId, locale);
      commit('setCurrentSpaceOfferCategoriesList', items);
    },
    async listOffers({ commit, rootState }, { locale = 'fr', category_to_include = undefined }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;
      const offerList = await Vue.prototype.$services.offer.listOffers(rootState.space.currentPartnerId, locale, 1, 200, categories_to_include);
      commit('setOffers', offerList);
      return offerList;
    },

    async listGiftCertificateConsumerEnabledOffers({ commit }, { locale = 'fr', category_to_include = undefined, region_to_include = undefined }) {
      const regions_to_include = region_to_include ? [region_to_include] : undefined;
      const categories_to_include = category_to_include ? [category_to_include] : null;

      const offerList = await Vue.prototype.$services.offer.listHomeOffers(locale, 1, 200, categories_to_include, regions_to_include, ['available'], true);
      commit('setGiftCertificateConsumerEnabledOfferList', offerList.items);

      return offerList;
    },

    async listHomeOffersSeparatedByRegions({ getters }, { locale = 'fr', category_to_include = undefined, region_to_include = undefined }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;

      const promiseArray = [];
      const filterableRegions = getters.filterableRegions;
      for (let i = 0; i < filterableRegions.length; i++) {
        const region = filterableRegions[i];
        const limit = region === region_to_include ? FOCUSED_REGION_OFFER_LIMIT : DEFAULT_SECTION_OFFER_LIMIT;
        promiseArray.push(Vue.prototype.$services.offer.listHomeOffers(locale, 1, limit, categories_to_include, [region.toUpperCase()]));
      }
      const resolvedPromises = await Promise.all(promiseArray);

      const offersByRegion = {};
      for (let i = 0; i < filterableRegions.length; i++) {
        const region = filterableRegions[i];
        offersByRegion[region] = resolvedPromises[i];
      }
      return offersByRegion;
    },

    async listHomeOffers({ getters }, { locale = 'fr', category_to_include = undefined }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;
      const region_to_include = [getters.currentRegion.toUpperCase()];

      const limit = DEFAULT_SECTION_OFFER_LIMIT;
      return await Vue.prototype.$services.offer.listHomeOffers(locale, 1, limit, categories_to_include, region_to_include);
    },

    async listHomeRegionOffersGroupByStatus({ state, getters }, { locale = 'fr', region_to_include, focus_status = 'available', category_to_include = undefined, focus_items_limit = undefined }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;
      const regions_to_include = [region_to_include.toUpperCase()];

      const promiseArray = [];
      const statusOptions = getters.statusOptions;
      const statusFilters = state.statusSectionFilters;
      for (let i = 0; i < statusOptions.length; i++) {
        const option = statusOptions[i];
        const statuses_to_include = statusFilters[option];
        const limit = option === focus_status ? focus_items_limit || FOCUSED_STATUS_OFFER_LIMIT : DEFAULT_SECTION_OFFER_LIMIT;
        promiseArray.push(Vue.prototype.$services.offer.listHomeOffers(locale, 1, limit, categories_to_include, regions_to_include, statuses_to_include));
      }
      const resolvedPromises = await Promise.all(promiseArray);

      const offersByStatus = {};
      for (let i = 0; i < statusOptions.length; i++) {
        const status = statusOptions[i];
        offersByStatus[status] = resolvedPromises[i];
      }
      return offersByStatus;
    },
    async listHomeOffersGivenStatus({ state }, { locale = 'fr', region_to_include, status_to_include, category_to_include = undefined, page = 1, limit = 200, consumer_enabled = null }) {
      const categories_to_include = category_to_include ? [category_to_include] : null;
      const regions_to_include = [region_to_include.toUpperCase()];

      const statusFilters = state.statusSectionFilters;
      const statuses_to_include = statusFilters[status_to_include];

      const offers = await Vue.prototype.$services.offer.listHomeOffers(locale, page, limit, categories_to_include, regions_to_include, statuses_to_include, consumer_enabled);

      return offers;
    },

    async listHomeOffersByStatus({ dispatch }, { locale = 'fr', region_to_include, status_to_include, category_to_include = undefined }) {
      const offers = await dispatch('listHomeOffersGivenStatus', { locale, region_to_include, status_to_include, category_to_include });
      return offers.items;
    },
    async loadMoreOffersByStatus({ dispatch }, { locale = 'fr', region_to_include, status_to_include, category_to_include = undefined, page, limit }) {
      return dispatch('listHomeOffersGivenStatus', { locale, region_to_include, status_to_include, category_to_include, page, limit });
    },
    async refreshCurrentOfferAndOfferList({ dispatch }, { offer_id }) {
      await dispatch('listOffers', {});
      await dispatch('getOfferDetail', offer_id);
    },
    async refreshCurrentOfferAndPublisher({ dispatch }, { offer_id, publisher_id }) {
      await dispatch('getOfferDetail', offer_id);
      await dispatch('getOfferPublisher', { offer_id, publisher_id });
    },
    async refreshCurrentOfferAndPublishers({ dispatch }, { offer_id }) {
      await dispatch('getOfferDetail', offer_id);
      await dispatch('getOfferPublishers', { offer_id });
    },
  },
};
